import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from '../components/footer/login'
import { LoginState, LoginProps } from '../interfaces/user'
import { connect } from 'react-redux'
import { loginUser } from '../redux'
import "./login.css"

class Login extends Component<LoginProps, LoginState> { 
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            password: "",
            lat:0,
            lng:0
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "DDS | Login"
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { email, password,lat,lng } = this.state;
        this.props.loginUser(email, password,lat,lng);
    }
    render() {
        let msg;
        let messcolor;
        const { isLoggedIn, message } = this.props;
        if (isLoggedIn === false) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page login-page">
                <div className="form-wrapper">
                    <div className="form-card">
                           
                        <div className="p-5">
                            <div className='mb-3'>
                                <div className="form-card-header">
                                    <img className='login-form-logo' src={'/assets/img/domLogo.png'} alt="Dominos" />
                                    <h1 className='form-header-text'>Domino's Delivery System</h1>
                                </div>
                            </div>
                            <div className='my-3'>        
                                <form className="form-validate">
                                    <div className="form-group">
                                        <label className="form-control-label">Email</label>
                                        <input id="login-username" type="text" name="email" required data-msg="Please enter your email" className="input-material" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="form-group-material">
                                        <label className="form-control-label">Password</label>
                                        <input id="login-password" type="password" name="password" required data-msg="Please enter your password" className="input-material" onChange={this.handleInputChange} />
                                    </div>
                                    <button id="login" onClick={this.handleSubmit} className="btn btn-block btn-danger">Login</button>
                                </form>

                            </div>
                            <div className='mt-3'>
                                <Link to="forgot" className="text-danger">Forgot Password?</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        message: state.user.message,
        isLoggedIn: state.user.isLoggedIn
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        loginUser: function (email: any, password: any,lat:any,lng:any) {
            dispatch(loginUser(email, password,lat,lng));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
