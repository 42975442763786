import {
  ALL_GROUPS,
  HANDLE_INPUT,
  MENU_ITEMS,
  ORDERS_COUNTER,
  ITEM_DETAIL,
  HANLE_ADDRESS_INPUT,
  STORES_LIST,
  HANDLE_GEOCODE_ADDRESS,
  GET_TAX_CASH,
  GET_TAX_CARD,
  BACKUP_STORES_LIST,
  COUPON_DATA,
  SAVE_DELIVERY_TIME,
  SAVE_DISCOUNT,
  SAVE_TAX,
  SAVE_CART_TOTAL,
  SAVE_DELIVERY_FEE,
  ORDER_PERIOD,
  STORES_OPTIONS,
  LOADING_INDICATOR,
  ORDER_RESET,
  HOUSE_NO,
  STREET_NAME,
  STREET_NO,
  ADD_INFO,
  SELECTED_ADDRESS,
} from './menuType';
import Api from '../../components/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as geolib from 'geolib';
import { SELECT_STORE } from './customerType';
import { clearCart } from './cartAction';
import { EncryptionKey } from '../../client-config';
var CryptoJS = require("crypto-js");

toast.configure()
export const handleMenuInput = (event: { target: { name: any; value: any; }; }) => {
  return {
    type: HANDLE_INPUT,
    input: event.target
  }
}
export const handleAddInfo = (value:any) => {
  return {
    type: ADD_INFO,
    payload: value
  }
}

export const handleHouseNo = (address: String) => {
  return async (dispatch: any) => {
    dispatch({
      type: HOUSE_NO,
      payload: address
    })
  }
}
export const handleStreetName = (address: String) => {
  return async (dispatch: any) => {
    dispatch({
      type: STREET_NAME,
      payload: address
    })
  }
}
export const handleStreetNo = (address: String) => {
  return async (dispatch: any) => {
    dispatch({
      type: STREET_NO,
      payload: address
    })
  }
}
export const handleAddressInput = (address: any) => {
  return {
    type: HANLE_ADDRESS_INPUT,
    address: address,
  }
}
export const handleGeoCodeAddress = (address: any) => {
  return {
    type: HANDLE_GEOCODE_ADDRESS,
    geo_address: address,
  }
}
export const ordersCounter = () => {
  return async (dispatch: any) => {
    let token: any = localStorage.getItem('token');
    Api.get(`call-portal/orders_counter`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ORDERS_COUNTER,
            payload: response.data.successResponse[0]
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.warning({
            text: error,
            buttonText: 'OK',
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const allGroupsList = (store_id: any,channel:any) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING_INDICATOR });
    let obj: any = {
      mode: 'callcenter',
      channel: channel ? channel : null
    }
    if (store_id) {
      obj.store_id = store_id
    }
    else {
      obj.store_id = null
    }
    var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(obj), EncryptionKey).toString();
    Api.post(`menu/allGroups`, {body:encryptedData})
      .then((response) => {
        if (response.data.success) {
          console.log(response.data)
          dispatch({
            type: ORDER_RESET,
            payload: [],
            itemsData: {},
          });
          // dispatch(clearCart())
          dispatch({
            type: ALL_GROUPS,
            payload: response.data.groups,
            groupsData: response.data.groupsData
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
          dispatch({
            type: ALL_GROUPS,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const menuItemsListByGroupsID = (group_id: any) => {
  return async (dispatch: any) => {
    let obj = {
      mode: 'callcenter'
    }
    Api.post(`menu/items/${group_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_ITEMS,
            payload: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const getComboDetail = (store_id:any,combo_id: any) => {
  return async (dispatch: any) => {
    let obj = {
      mode: 'callcenter',
      store_id:store_id
    }
    var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(obj), EncryptionKey).toString();
    Api.post(`menu/combo/${combo_id}`, {body:encryptedData})
      .then((response) => {
        if (response.data.success) {
          console.log(response.data, "Item Details")
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const getItemDetail = (store_id:any, item_id: any) => {
  return async (dispatch: any) => {
    let obj = {
      mode: 'callcenter',
      store_id:store_id
    }
    var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(obj), EncryptionKey).toString();
    Api.post(`menu/item/${item_id}`, {body:encryptedData})
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: {},
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};

export const findLocalStores = (lat: any, lng: any) => {
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          for (let i = 0; i < Responedata.length; i++) {
            //find distance of two coordinates
            if (Responedata[i].trade_zone_id.zone_json) {
              if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].trade_zone_id.zone_json))) {
                data.push(Responedata[i]);
              }
            }
          }
          //after store is find out check if the store is active and if it not active,
          //then find its backup stores array and push the backup store

          if (data.length > 0 && data[0].is_active == 0) {
            if (data[0].mainStoreId.length > 0) {
              let backupStoreData: any = [];
              data[0].mainStoreId.map((backupStore: any) => {
                if (backupStore.backupStoreId.is_active == 1) {
                  backupStoreData.push(backupStore);
                }
              })
              //sorting array with priority basis if backup stores are greater than 1
              if (backupStoreData.length > 1) {
                backupStoreData.sort((a: any, b: any) => {
                  return a.priority - b.priority
                })
              }
              //finding the backupstore object from response data and match them by store_id
              let foundedStore: any = []
              if (backupStoreData.length > 0) {
                foundedStore.push(Responedata.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
                console.log(foundedStore);
                //Save the inactive store in selected nearest store and make another list of backup stores
                let storeOption = [{
                  label: data[0].store_name,
                  value: data[0].store_id
                }]
                dispatch({
                  type: STORES_OPTIONS,
                  payload: storeOption
                })
                dispatch({
                  type: STORES_LIST,
                  payload: data,
                  selectStoreId: data.length > 0 && data[0].store_id,
                  deliveryfee: 0,
                  storeStatus: 0,
                });
                dispatch({
                  type: BACKUP_STORES_LIST,
                  payload: backupStoreData
                })
                dispatch(saveTaxDetails(data[0].state_id.state_name, data[0].store_id))
              }
            }
            else {
              let storeOption = [{
                label: data[0].store_name,
                value: data[0].store_id
              }]
              dispatch({
                type: STORES_OPTIONS,
                payload: storeOption
              })
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data.length > 0 && data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0,
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name, data[0].store_id))
            }
          }
          else if (data.length > 0 && data[0].is_active === 1) {
            let storeOption = [{
              label: data[0].store_name,
              value: data[0].store_id
            }]
            dispatch({
              type: STORES_OPTIONS,
              payload: storeOption
            })
            dispatch({
              type: STORES_LIST,
              payload: data,
              selectStoreId: data.length > 0 && data[0].store_id,
              deliveryfee: 0,
              storeStatus: 0,
            });
            if (data.length > 0) {
              dispatch(saveTaxDetails(data[0].state_id.state_name, data[0].store_id))
            }
          }
          else if (data.length == 0) {
            toast.error("No store detected for this address. Kindly try more structured address")
            dispatch({
              type: STORES_OPTIONS,
              payload: []
            })
          }
          // dispatch(GetLSMitembyStoreId(data[0].store_id))
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
          });
        } else {
          alert("Connection Lost!!!!");
        }
      });
  };
};


//LocalStore with kml Coordinates Json
// export const findLocalStores = (lat: any, lng: any) => {
//     console.log("lat:", lat, "lng:", lng)
//     return async (dispatch: any) => {
//         Api.get("menu/stores")
//             .then((response) => {
//                 if (response.data.success) {
//                     let data: any = [];
//                     let Responedata = response.data.successResponse;
//                     console.log(Responedata)
//                     for (let i = 0; i < Responedata.length; i++) {
//                         if (Responedata[i].trade_zone_id.zone_json) {
//                             //find distance of two coordinates  
//                             if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].trade_zone_id.zone_json))) {
//                                 data.push(Responedata[i]);
//                             }
//                         }
//                     }
//                     if (data.length == 1) {
//                         dispatch({
//                             type: STORES_LIST,
//                             payload: data,
//                             selectStoreId: data.length > 0 && data[0].store_id,
//                             deliveryfee: data.length > 0 && data[0].delivery_fee,
//                             storeStatus: 0,
//                         });
//                     }
//                     else {
//                         dispatch({
//                             type: STORES_LIST,
//                             payload: response.data.successResponse,
//                           })
//                     }
//                 }
//             })
//             .catch((err) => {
//                 if (err.response) {
//                     let error;
//                     let data: any = [];
//                     if (typeof err.response.data.message === "string") {
//                         error = err.response.data.message;
//                     } else if (typeof err.response.data.message === "object") {
//                         error = err.response.data.message[0].replace(/_/g, " ");
//                     }
//                     dispatch({
//                         type: STORES_LIST,
//                         payload: data,
//                         deliveryfee: 0,
//                         storeStatus: 0,
//                     });
//                 } else {
//                     alert("Connection Lost");
//                 }
//             });
//     };
// };

// pickup store


export const findPickupStores = (lat: any, lng: any, searchString: any) => {
  console.log(searchString)
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == 'Disabled') {
              activeStores.push(store);
            }
          })
          const filteredStores = activeStores.filter((store: any) => {
            return (
              store.address
                .toLowerCase()
                .includes(searchString.toLowerCase()) ||
              store.city.toLowerCase().includes(searchString.toLowerCase()) ||
              store.store_name
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
          });
          if (filteredStores.length == 0) {
            for (let i = 0; i < activeStores.length; i++) {
              if (activeStores[i].zone_json) {
                if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(activeStores[i].zone_json))) {
                  data.push(activeStores[i]);
                }
              }
            }
            if (data.length == 0) {
              dispatch({
                type: STORES_LIST,
                payload: [],
                selectStoreId: "",
                deliveryfee: 0,
                storeStatus: 0,
              });
            } else {
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0, // 1 for out of Range
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name, data[0].store_id))

            }
            // Actions.selectstore();
          } else {
            dispatch({
              type: STORES_LIST,
              payload: filteredStores,
              selectStoreId: filteredStores[0].store_id,
              deliveryfee: 0,
              storeStatus: 0, // 1 for out of Range
            });
            dispatch(saveTaxDetails(filteredStores[0].state_id.state_name, filteredStores[0].store_id))

          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // Actions.selectstore();
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};
//saveSelectStoreId
export const saveSelectStoreId = (store: any,channel:any) => {
  return function (dispatch: any) {
    dispatch(allGroupsList(store.store_id,channel))
    dispatch({
      type: SAVE_DELIVERY_TIME,
      promiseTime: store.expected_delivery_time,
    });
    dispatch(saveTaxDetails(store.state_id.state_name, store.store_id))
  };
};

//save Backup stores in global state 
export const saveBackupStores = (backupStores: any) => {
  console.log(backupStores);
  return function (dispatch: any) {
    dispatch({
      type: BACKUP_STORES_LIST,
      payload: backupStores,
    });
  };
};

export const saveTaxDetails = (state_name: any, store_id: any, cart?: any) => {
  return function (dispatch: any) {
    let obj: any = {
      state_name: state_name,
      store_id: store_id,
      mode: 'callcenter'
    }
    if(cart && cart.length > 0){
      obj.cart = cart
    }
    Api.post(`menu/tax`,  obj)
      .then((response) => {
        if (response.data.success) {
          let responseData = response.data.successResponse
          responseData.map((taxVariations: any) => {
            if (taxVariations.payment_method == 'cash') {
              dispatch({
                type: GET_TAX_CASH,
                payload: taxVariations,
              });
              console.log(taxVariations.delivery_fee, "delivery feee here")
              dispatch({
                type: SAVE_DELIVERY_FEE,
                deliveryfee: taxVariations.delivery_fee
              });
            }
            else if (taxVariations.payment_method == 'card') {
              dispatch({
                type: GET_TAX_CARD,
                payload: taxVariations,
              });
              dispatch({
                type: SAVE_DELIVERY_FEE,
                deliveryfee: taxVariations.delivery_fee
              });
            }
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          // console.log(error);
        } else {
          // console.log("ok tax");
        }
      });
  }
}

export const applyCoupon = (data: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), EncryptionKey).toString();
    Api.post(`/menu/apply_coupon`, {body:encryptedData})
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_DATA,
            payload: response.data.coupon,
            successREsponse: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.error(error);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};
export const removeCoupon = () => {
  return async (dispatch: any) => {
      let token: any = localStorage.getItem('userToken');
      Api.delete(`/menu/remove_coupon`, {
          headers: { 'Authorization': 'Bearer ' + token }
      }).then((response) => {
          if (response.data.success) {
              dispatch({
                  type: COUPON_DATA,
                  couponReady: true
              });
              toast.info(response.data.successResponse, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
          }
      })
  };
};
export const resetCoupon = () => {
  return async (dispatch: any) => {
      dispatch({
          type: COUPON_DATA,
          payload: {}
      });
  }
}
export const consumeCoupon = (data: any) => {
  return async (dispatch: any) => {
      let token: any = localStorage.getItem('userToken');
      var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), EncryptionKey).toString();
      console.log(data,"dasdasdasdasd");
      
      Api.post(`/menu/consume_coupon`, { body: encryptedData }, {
          headers: { 'Authorization': 'Bearer ' + token }
      }).then((response) => {
          if (response.data.success) {
              if (response.data.removeCoupon) {
                  dispatch({
                      type: COUPON_DATA,
                      couponReady: true
                  });
                  toast.info(response.data.successResponse, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
              }
          }
      }).catch((err) => {
          if (err.response) {
              let error;
              if (typeof err.response.data.message === 'string') {
                  error = err.response.data.message;
              } else if (typeof err.response.data.message === 'object') {
                  error = err.response.data.message[0].replace(/_/g, ' ');
              }
              toast.error(error, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
          }
      });
  };
};
// Save customer tax value
export const SaveTaxValue = (taxAmount: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_TAX,
      taxAmount: taxAmount,
    });
  };
};

// Save customer totalDiscount
export const SaveDiscountValue = (discountValue: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_DISCOUNT,
      discountValue: discountValue,
    });
  };
};

// Save customer cart total
export const SaveCartTotal = (cart_total: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_CART_TOTAL,
      CartTotal: cart_total,
    });
  };
};
//save order period value
export const saveOrderPeriod = (period: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_PERIOD,
      orderPeriod: period,
    });
  };
};


export const storesListForMultiSelect = () => {
  return function (dispatch: any) {
    let token: any = localStorage.getItem('token');
    Api.get('/admin/storesListForMenu', {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        console.log("print console for store option", response.data)
        if (response.data.success) {

          dispatch({
            type: STORES_OPTIONS,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}

export const UpdateMenuItems = (data: any) => {
  return async (dispatch: any) => {
    console.log(data)
    dispatch({
      type: MENU_ITEMS,
      payload: data.menuItemsData
    });
  }
}


export const findLocalStoreWithoutMap = (store: any) => {
  return async (dispatch: any) => {
    if (store) {
      let data: any = [];
      data.push(store)
      //after store is find out check if the store is active and if it not active,
      //then find its backup stores array and push the backup store

      if (data.length > 0 && data[0].is_active == 0) {
      }
      else if (data.length > 0 && data[0].is_active === 1) {
        console.log('data', data);

        let storeOption = [{
          label: data[0].store_name,
          value: data[0].store_id
        }]
        dispatch({
          type: STORES_OPTIONS,
          payload: storeOption
        })
        dispatch({
          type: STORES_LIST,
          payload: data,
          selectStoreId: data.length > 0 && data[0].store_id,
          deliveryfee: 0,
          storeStatus: 0,
        });
        if (data.length > 0) {
          dispatch(saveTaxDetails(data[0].state_id.state_name, data[0].store_id))
        }
      }
      else if (data.length == 0) {
        console.log('last', data);

        toast.error("No store detected for this address. Kindly try more structured address")
        dispatch({
          type: STORES_OPTIONS,
          payload: []
        })
      }
      // dispatch(GetLSMitembyStoreId(data[0].store_id))
    }
  };
};

export const getTimezone = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return response.data.successResponse
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}

export const getTimezoneForLater = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return { time: response.data.successResponse, date: response.data.successLaterResponse }
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}
export const saveSelectedAddress = (address: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SELECTED_ADDRESS,
      payload: address,
    });
  };
};
export const resetItemSize = () => {
  return function (dispatch: any) {
    dispatch({
      type: ITEM_DETAIL,
      payload: [],
    });
  }
}
export const saveDeliveryFeeForCoupon = (fee:Number) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_DELIVERY_FEE,
      deliveryfee: fee
    });
  }
}
