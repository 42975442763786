import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import "./index.css"

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        return (
            <div className="copyrights text-center">
                <p>
                    Powered By{" "}
                    <a style={{ textDecoration: "none" }} className="external powered-by" href="https://simplexts.net/" target="/blank">
                        SimpleX Technology Solutions
                    </a>
                </p>
            </div>
        );
    }
};