import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import _ from "lodash";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import axios from 'axios'
import {
  addCustomer,
  customerDetail,
  getCart,
  getStores,
  getTaxValue,
  handleAddressInput,
  handleCustomerInput,
  handleMenuInput,
  logoutUser,
  saveCart,
  validateEmail,
} from "../../redux";
import './orderDetail.css'
import './customerDetail.css'
import { CustomerProps, CustomerState } from "../../interfaces/customer";
import BeautyStars from "beauty-stars";
import OrderDetail from "./orderDetail";
import Complaint from "./complaint";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Select from 'react-select';
import {
  submitComplaint,
  TimerStart,
  trackOrder,
  stopCount,
  addAddressUser,
  addressesListk,
  // isSavedAddressSelected,
  // editAddress,
  // receiveCustomerData,
  saveCallrecord,
  // getPSI,
  orderDetail,
  receiveCustomerData,
  editAddress,
  isSavedAddressSelected,
  BlockCustomer,
  ActivateAccount
} from "../../redux/actions/customerAction";
import { StartClockTimer, stopTimer } from "../../redux/actions/headerAction";
import { threadId } from "worker_threads";
import { ComplaintState } from "../../interfaces/complaint";
import moment from "moment";
import { toast } from "react-toastify";
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { getCitiesWeb, getTradeZonesWeb, getTradeAreas, setTradeZoneName, setCityName, setTradeAreaName } from "../../redux/actions/trade_zones_areas_Action";
import {
  findLocalStoreWithoutMap, findLocalStores, handleHouseNo,
  handleStreetName,
  handleStreetNo,
  saveSelectedAddress,
  // saveSelectedAddress
} from "../../redux/actions/menuAction";
// import { brandsList, saveBrand } from "../../redux/actions/brandAction";
import { API_URL, BASE_URL, priceunit } from "../../client-config";
import { showorderItems, updateSelectedRow } from "../../redux/actions/orderAction";
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
// import { getAgentTrgetByAgentId } from "../../redux/actions/agentAction";
import Invoice from "../order/FbrInvoice/fbrInvoice";
let selectedOrderId = 0;
class ActionFormatter extends Component<{ row: any, data: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showorderItems = async (id: any) => {
    const { showorderItemss } = this.props.data;
    if (showorderItemss) {
      try {
        await showorderItemss(id, this.props.data.orders);
        selectedOrderId = id
      } catch (err: any) {
      }
    }
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
      </div>
    )
  }
}

// class InvoiceFormator extends Component<{ row: any }, { selectedOrderId: any }> {
//   constructor(readonly props: any) {
//     super(props);
//     this.state = {
//       selectedOrderId: "",
//     }
//   }
//   showorderItems = (id: any) => {
//     this.props.data.showorderItems(id, this.props.data.orders);
//     this.setState({ selectedOrderId: id });
//   }
//   render() {
//     const { row, data } = this.props;
//     return (
//       <div>
//         {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
//         <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
//         {/* <!-- Modal--> */}
//         {/*Invoice Modal */}
//         <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
//           <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
//                 <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
//               </div>
//               <div className="modal-body">
//                 <Invoice data={data} orderId={this.state.selectedOrderId} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }
class ReOrderFormator extends Component<{ row: any, data: any }, { selectedOrderId: any }> {
  constructor(props: any) {
    super(props);
    this.state = { selectedOrderId: "" };
  }

  orderForReOrder = async (id: any, mode: any) => {
    const { reOrdersFunction } = this.props.data;
    if (reOrdersFunction) {
      try {
        if (id.channel == "delivery") {
          if (this.props.data.selectedAddress && this.props.data.selectedAddress.isClicked) {
          }
          else {
            const matchedAddress = this.props.data.addressData.find(
              (address: any) => address.address_id == id.address_id
            );
       
            if (matchedAddress) {

              this.props.data.saveSelectedAddress(matchedAddress)
              this.props.data.setCityName(matchedAddress.city_name);
              this.props.data.getTradeZones(matchedAddress.city_id);
              this.props.data.setTradeAreaName(matchedAddress.area_name, matchedAddress.area_id);
              this.props.data.setTradeZoneName(matchedAddress.zone_name, matchedAddress.zone_id);
              this.props.data.getTradeAreas(matchedAddress.zone_id);
              this.props.data.setHouseNo(matchedAddress.full_address);
            } else {
              console.warn("No address found with the given address_id.");
            }
          }
        }
        else if (id.channel == "pickup") {
          if (this.props.data.selectedAddress && this.props.data.selectedAddress.isClicked) {
            const matchedAddress = this.props.data.addressData.find(
              (address: any) => address.address_id == this.props.data.selectedAddress.address_id
            );
            if (matchedAddress) {

              this.props.data.saveSelectedAddress(matchedAddress)
              this.props.data.setCityName(matchedAddress.city_name);
              this.props.data.getTradeZones(matchedAddress.city_id);
              this.props.data.setTradeAreaName(matchedAddress.area_name, matchedAddress.area_id);
              this.props.data.setTradeZoneName(matchedAddress.zone_name, matchedAddress.zone_id);
              this.props.data.getTradeAreas(matchedAddress.zone_id);
              this.props.data.setHouseNo(matchedAddress.full_address);
            } else {
              console.warn("No address found with the given address_id.");
            }
          }
          else {
            this.props.data.saveSelectedAddress({ channel: id })
            this.props.data.setCityName(id.city_name);
            this.props.data.getTradeZones(id.city_id);
          }
        }
        setTimeout(async () => {
          await reOrdersFunction(id.order_id, mode);
        }, 200);
      } catch (err: any) {
        console.error("Reorder failed:", err.message);
      }
    }
  };

  render() {
    const { row, data } = this.props;
    return (
      <div>
        <Link to="/menu"> <button
          title="Re Order"
          className="btn btn-outline-info ml-2"
          onClick={() => this.orderForReOrder(row, row.mode)}
        >
          <i className="fa fa-repeat"></i>
        </button>
        </Link>
      </div>
    );
  }
}


const paginationOptions = {
  sizePerPage: 10,
  showTotal: false,
  sizePerPageList: [],
};


{/* <TableHeaderColumn
   dataField="action"
   dataFormat={actionFormatter}
   formatExtraData={this.props}
   export={false}
    >
  Repeat Order
</TableHeaderColumn> */}

function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `Rs. ${cell}`;
  } else {
    return `Rs. ${Math.round(cell)}`;
  }
}

function dateFormatterData(cell: any, row: any, props: any) {
  const dateObject = new Date(row.date_created);

  // Define the options for the date format
  const options = {
    year: 'numeric',
    month: 'long', // Get month name in English
    day: 'numeric',
    weekday: 'long', // Get weekday name in English
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true, // Use 12-hour time format (AM/PM)
  };

  return dateObject.toLocaleString('en-US', options);
}

//will be comment out when orders coming from api
function InvoiceComp(props: any) {
  return (
    <div id={`viewinvoice`} data-backdrop='static' data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
      <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
            <button type="button"
              data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
          </div>
          <div className="modal-body">
            <Invoice data={props.data} orderId={selectedOrderId} />
          </div>
        </div>
      </div>
    </div>
  )
}
function reOrderFormatter(c: any, row: any, a: any, props: any) {
  return <ReOrderFormator row={row} data={props} />;
}
function actionFormatter(c: any, row: any, a: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
function complaintFormatter(cell: any, row: any, a: any, props: any) {
  return (
    <button
      title="Feedback"
      className="btn btn-outline-primary"
      data-toggle="modal"
      data-target={`#RegFeedback`}
      onClick={() => {
        props.updateSelectedRow(row)
      }}
    >
      <i className="fa fa-comment"></i>
    </button>
  );
}
class Map extends Component<
  {
    handleAddressInput: (address: any) => {};
    findLocalStores: (lat: any, lng: any) => {};
  },
  { lat: any; lng: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
    return false;
  }
  onPlaceSelected = (place: any) => {
    if (place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      this.setState({ lat: latValue, lng: lngValue });
      this.props.handleAddressInput(address);
      this.props.findLocalStores(latValue, lngValue);
    }
  };
  auto = () => {
    const AsyncMap: any = withScriptjs(
      withGoogleMap((props: any) => (
        <Autocomplete
          style={{
            width: "100%",
            height: "40px",
            marginBottom: "15px",
            paddingLeft: "16px",
            marginTop: "2px",
          }}
          onPlaceSelected={this.onPlaceSelected}
          types={["address"]}
          componentRestrictions={{ country: "pk" }}
        />
      ))
    );
    let map;
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDGq7xWqvDvuvqwyTGJZDjfcRy1aCRmvt8&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "0px" }} />}
        containerElement={<div style={{ height: this.props.height }} />}
        mapElement={<div style={{ height: "0px" }} />}
      />
    );
    return map;
  };
  render() {
    return <div>{this.auto()}</div>;
  }
}
let hasRun = false;
declare global {
  interface Window {
    myFunction: (data: any) => void;
  }
  interface Window {
    myPublicFunction: (data: any) => void;
  }
}
function ComplaintComp(props: any) {
  return (
    <div
      id={`RegFeedback`}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      className="modal fade text-left"
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">
              Customer Feedback
            </h4>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Complaint />
          </div>
        </div>
      </div>
    </div>
  );
}
const formatDateTime = (dateTime: any) => {
  const dateObject = new Date(dateTime);
  return dateObject.toLocaleString();
};
class CustomerDetail extends Component<CustomerProps, CustomerState> {
  customerNumber: any;
  selectRef: any;
  selectRef2: any;
  selectRef3: any;
  constructor(readonly props: any) {
    super(props);
    // ===================
    this.state = {
      phone: "",
      fullname: "",
      email: "",
      googlesearch: '',
      placesSuggestion: [],
      feedbackType: "",
      receiptnumber: "",
      storeId: "",
      dateOfOrder: "",
      isOpen: false,
      feedbackMessage: "",
      sizeIndex: "",
      showDetails: false, // Flag to track if details are visible or not
      selectedOrder: null, // Track the selected order
      isValidPhone: "",
      orderId: "",
      newUserphone: "",
      isValidEmail: true,
      newUser: false,
      traceOrderby: "Phone",
      editable_fullAddress: "",
      sizejson: [],
      addressesList: [],
      place: "",
      editAddress_id: "",
      isUpdateBtnDisabled: true,
      addAddress: false,
      editAddress: false,
      area: '', building: '', room: "",
      edit_placesSuggestion: [],
      city_id: "",
      zone_id: "",
      tableIndex: "",
      currentPage: 1,
      pageSize: 10,
      index: "",
      brand_id: "",
      timeoutId: "",
      selectedAddress: 0
    };

    const urlPhone = localStorage.getItem("urlPhone");
    if (urlPhone && urlPhone != "") {
      window.location.href = "/customerDetail?number=" + urlPhone;
      this.submitPhone(urlPhone, "");
      localStorage.setItem("urlPhone", "");
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.customerNumber = params.get("number");
      if (this.customerNumber !== "" && this.customerNumber !== null) {
        this.submitPhone(this.customerNumber, "");
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmitComplaint = this.handleSubmitComplaint.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.brandFormatter = this.brandFormatter.bind(this);
    this.genesysCustomerData = this.genesysCustomerData.bind(this);
  }
  myFunction = (phone: any) => {
    this.setState({ isValidPhone: true })
    if (phone) {
      this.setState({ phone: phone })
      this.props.receiveCustomerData(phone)
    }
  }
  handleScreeenZoom() {
    const windowWidth = window.innerWidth;
    if(windowWidth >= 1900) {
      document.body.style.zoom = "88%";
    }
    else if(windowWidth >= 1800 && windowWidth < 1900) {
      document.body.style.zoom = "80%";
    }
    else if(windowWidth >= 1600 && windowWidth < 1800) {
      document.body.style.zoom = "75%";
    }
    else if(windowWidth >= 1200 && windowWidth < 1600) {
      document.body.style.zoom = "67%"; 
    }
    else if(windowWidth >= 1000 && windowWidth < 1200) {
      document.body.style.zoom = "60%";
    }
    else if(windowWidth >= 800 && windowWidth < 1000) {
      document.body.style.zoom = "50%";
    }
    else {
      document.body.style.zoom = "100%";
    }
  }
  componentDidMount() {
    this.handleScreeenZoom()
    // const script = document.createElement("script");
    // script.src = '../public/assets/js/iwsprescript.js';
    // script.async = true;
    // document.body.appendChild(script);
    // script.onload=()=>{
    //   myExternalFunction()
    // }
    this.props.saveSelectedAddress(null)
    // this.props.psiListData()
    this.props.storesList();
    // this.props.brandsList();
    this.props.complaintReasonsList();
    document.title = "DDS | Customer Detail";
    localStorage.removeItem("flag")
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let customerNumber = params.get("number");

    if (customerNumber !== "" && customerNumber !== null) {
      this.setState({ phone: customerNumber, isValidPhone: true });
      let newUserphone = this.TrimNumber(customerNumber);
      this.setState({ newUserphone: newUserphone })
    }
    this.props.getCities()
  }
  testFunct = (phone: any) => {
    if (phone) {
      this.setState({ phone: phone })
      this.props.receiveCustomerData(phone)
    }
  }
  onCloseModal = () => {
    this.setState({ isOpen: false });
    localStorage.removeItem("popup")
  };
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const queryParams = new URLSearchParams(this.props.location.search);
    const callerId = queryParams.get('callerId');
    // console.log("nextProps::", nextProps);
    if (this.props.callduration === nextProps.callduration) {
      nextProps.addressData.map((d: any) => {
        Object.assign(d, { is_edit: false });
      });
      this.setState({ addressesList: nextProps.addressData });
    }
    if (this.props.customerPhoneData) {
      this.setState({ isValidPhone: true, phone: this.props.customerPhoneData });
    }
    if(nextProps?.customer && Object.keys(nextProps.customer).length === 0) {
      this.setState({
        city_id: "",
        zone_id: "",
        area: "",
        building: "",
        place: ""
      })
    }
    let Phonedata = localStorage.getItem("customerPhone");
    // console.log("PhoneDataInSession", Phonedata);
    if (Phonedata && !hasRun) {
      this.props.receiveCustomerData(Phonedata);
      hasRun = true;
      if (nextProps.time == 0) {
        // console.log("reached-here9000", nextProps.time)
        nextProps.StartClockTimer(nextProps.time);
      }
    // geneses order counter time
    // let {time} = this.props;
    // console.log(time)
    // time = time/60;
    // let rounded_time = Math.round(time*10)/10
    //   let callStatsObj = {
    //       call_duration: rounded_time,
    //       call_status: 1,
    //       customer_number: Phonedata,
    //       total_sale: 0,
    //     }
    //     this.props.saveCallrecord(callStatsObj);
    //   this.props.stopTimer()
    }
    if(nextProps?.addressData && nextProps?.addressData.length > 0 && nextProps?.addressData !== this.props?.addressData) {
      let defaultAddress = nextProps.addressData[0]

      defaultAddress.isClicked = true
      this.props.saveSelectedAddress(defaultAddress)
      
      this.setState({ 
        building: defaultAddress.extra_details, 
        place: defaultAddress.place, 
        isUpdateBtnDisabled: false,
        selectedAddress: defaultAddress.address_id
      })

      this.props.setCityName(defaultAddress.city_name)
      this.props.getTradeZones(defaultAddress.city_id)
      this.props.setTradeAreaName(defaultAddress.area_name, defaultAddress.area_id)
      this.props.setTradeZoneName(defaultAddress.zone_name, defaultAddress.zone_id)
      this.props.getTradeAreas(defaultAddress.zone_id)
      this.props.setHouseNo(defaultAddress.extra_details)
      this.props.setStreetName(defaultAddress.street_name)
      this.props.setStreetNo(defaultAddress.street_number)

    }
  }
  componentWillUnmount() {
    document.body.style.zoom = "100%";
  }

  // handleBrand = (e: any) => {
  //   try {
  //     const brand_id = JSON.parse(e.target.value);
  //     if (brand_id) {
  //       // console.log("reached-here777", brand_id);
  //       this.setState({ brand_id: brand_id.brand_id });
  //        // Store the selected brand name in session storage
  //       // localStorage.setItem('selectedBrandName', brand_id.brand_name);
  //         localStorage.setItem("brand_id", brand_id.brand_id)
  //         localStorage.setItem("brand_name", brand_id.brand_name)
  //         this.props.saveBrand(e.target.value)
  //         this.props.storesList()
  //         this.props.saveStore()
  //       }

  //   } catch (error) {
  //     console.error("Error parsing JSON:", error);
  //   }
  // };

  handleAddClick = (e: any, ind?: any) => {
    this.setState({ sizeIndex: ind, addAddress: true, editAddress: false })
    if (this.state.sizejson.length == 1 && this.state.addAddress) {
      toast.error("Only one address can be added at a time")
    }
    else {
      let customer_id = localStorage.getItem('customer');
      const newlist: any = { full_address: "", place: "Home", customer_id: customer_id }
      const size: any = this.state.sizejson.concat(newlist)

      this.props.setCityName("")
      this.props.setTradeAreaName("")
      this.props.setTradeZoneName("")
      this.props.setHouseNo("")
      this.setState({ sizejson: size, isUpdateBtnDisabled: false, addAddress: true, index: this.state.addressesList.length, building: "" });
    }
    e.preventDefault()
  };
  editAddressField = (addressObj: any, index: any) => {
    if (addressObj) {
      this.props.saveSelectedAddress(addressObj)
      this.setState({ building: addressObj.extra_details, editAddress: true, place: addressObj.place, isUpdateBtnDisabled: false, city_id: addressObj.city_id, zone_id: addressObj.zone_id, area: addressObj.area_id })
      this.props.setCityName(addressObj.city_name)
      this.props.getTradeZones(addressObj.city_id)
      this.props.setTradeAreaName(addressObj.area_name, addressObj.area_id)
      this.props.setTradeZoneName(addressObj.zone_name, addressObj.zone_id)
      this.props.getTradeAreas(addressObj.zone_id)
      this.props.setStreetName(addressObj.street_name)
      this.props.setStreetNo(addressObj.street_number)
      // this.setState({ editable_fullAddress: addressObj.full_address, isUpdateBtnDisabled: false, editAddress: true })

    }
    // let { addressesList } = this.state
    // this.setState({ editable_fullAddress: addressObj.full_address, isUpdateBtnDisabled: false, editAddress: true })
    // addressesList.forEach((address: any) => {
    //   if (address.address_id == addressObj.address_id) {
    //     address.enable = true;
    //   }
    // })
    // this.setState({
    //   addressesList: addressesList,
    //   editAddress_id: addressObj.address_id,
    //   index: index
    // })
  }

  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    // console.log('value', value);

    const list: any = this.state.addressesList;
    list[index][name] = value;
    this.setState({ addressesList: list });
    if (name == "pos_code") {
      this.props.checkDuplicatePosCode(value)
    }
    if (name == "erp_id") {
      this.props.checkDuplicateErpId(value)
    }
  };

  handleNewVariantInputChange = (e: any, index: any) => {
    if (index === 3) {
      this.setState({ place: e.target.value });
    }
    else {
      // const { name, value } = e.target;
      // const list: any = this.state.sizejson;
      // list[index][name] = value;
      this.setState({ place: e.target.value });

    }
  };
  handleBlockCustomer = () => {
    let obj = {
      is_active: 0
    }
    this.props.BlockCustomer(obj);
  }
  handleActivation = () => {
    this.props.handleActivation();
  }
  handleUnBlockCustomer = () => {
    let obj = {
      is_active: 1
    }
    this.props.BlockCustomer(obj);
  }

  handleAddressSubmit = (type: any) => {

    // let { city_id, zone_id, area, building, place } = this.state
    let { city_id, zone_id, building, place, area } = this.state
    let customer_id: any = localStorage.getItem('customer');
    let obj: any = {
      extra_details: building,
      place: place,
      city_id: city_id,
      zone_id: zone_id,
      area_id: area,
      customer_id: Number(customer_id),
      is_default: 0,
      street_name: this.props.streetName,
      street_number: this.props.streetNo,
      // full_address:area + " " + building + " " + zone_id + " " + place + " " + city_id
      full_address: building + " " + zone_id + " " + place + " " + city_id
    }

    if (obj) {
      this.props.addAddress(obj);
      // this.setState({first_address: 1})
      this.setState({ addAddress: false })
    }

    // let { addressesList, googlesearch, area, building, room, editable_fullAddress } = this.state;
    // let value = this.state.editAddress_id == '' ? googlesearch : editable_fullAddress
    // let full_address = area + " " + building + " " + room + " " + value;
    // if ((googlesearch === '' && area === '' && building === '' && room === '')) {
    //   console.log("list if", addressesList)
    //   this.props.addAddress(addressesList);
    // }
    // else {
    //   console.log("list else")
    //   let list: any = this.state.sizejson;

    //   // let obj = { full_address: event.target.value }
    //   let obj = { full_address: full_address }
    //   list.length > 0 ? list[0].full_address = full_address : list.push(obj)
    //   // list[0].full_address = full_address
    //   const dupAddressArr: any = this.state.addressesList.concat(list);
    //   console.log('list', dupAddressArr);
    //   this.setState({ addressesList: dupAddressArr })
    //   this.props.addAddress(dupAddressArr);
    //   this.setState({ sizejson: [], area: '', googlesearch: '', room: '', building: '' })
    // }
    // this.setState({ isUpdateBtnDisabled: true, googlesearch: '', editAddress_id: '' })

  };
  isEmptyInputFields = () => {
    // let { city_id, zone_id, area, building, place } = this.state
    let { city_id, zone_id, building, place } = this.state
    // return !(city_id && zone_id && area && building);
    return !(city_id && zone_id && building);
  }
  handleEditSubmit = async (type: any) => {
    // let { city_id, zone_id, area, building, place } = this.state
    let { city_id, zone_id, building, place, area } = this.state


    // await this.setState({ city_id: this.props.selectedAddress.city_id?.id, zone_id: this.props.selectedAddress.zone_id?.id, area: this.props.selectedAddress.area_id?.id })
    await this.setState({ city_id: this.props.selectedAddress.city_id?.id, zone_id: this.props.selectedAddress.zone_id?.id })
    let customer_id: any = localStorage.getItem('customer');
    setTimeout(() => {
      let obj: any = {
        extra_details: building,
        // full_address:area + " " + building + " " + zone_id + " " + value,
        place: place,
        city_id: city_id,
        zone_id: zone_id,
        area_id: area,
        street_name: this.props.streetName,
        street_number: this.props.streetNo,
        customer_id: Number(customer_id),
        is_default: 0
      }

      if (obj) {
        this.props.editAddress(this.props.selectedAddress.address_id, obj)
        this.setState({ editAddress: false })
      }
    }, 50);
  };

  validatePhone(event: { target: { name: any; value: any; }; }) {

    const reg = /^[0-9\b]+$/;

    let phone_number = event.target.value;

    if(phone_number !== "" && !reg.test(phone_number)) {
      return;
    }

    if (phone_number.length === 11 && phone_number.charAt(0) === '0' && phone_number.charAt(1) === '3') {
      this.setState({ [event.target.name]: event.target.value, isValidPhone: true, index: null })
    } else {
      this.setState({ [event.target.name]: event.target.value, isValidPhone: false, index: null })
    }

    sessionStorage.removeItem("flag")
  };
  TrimNumber = (phone_number: any) => {
    let str_phone_number = phone_number.toString();
    if (str_phone_number.charAt(0) === '+') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number
    }
    if (str_phone_number.charAt(0) === '9') {
      str_phone_number = str_phone_number.substring(2);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.charAt(0) === ' ') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.length == 8) {
      str_phone_number = "0" + str_phone_number;
    }
    return str_phone_number;
  }
  submitPhone = (phone_number: any, order_id: any, callerId?: string) => {

    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    // refined_phone_number = this.TrimNumber(phone_number);
    if (phone_number != "") {
      let obj: any = {
        // phone_number: refined_phone_number,
        brand_id: this.props.brand && this.props.brand.brand_id,
        phone_number: phone_number
      };
      // this.setState({ phone: phone_number, isValidPhone: true, newUserphone: refined_phone_number });
      this.setState({ phone: phone_number, isValidPhone: true, newUserphone: phone_number });
      this.setState({ newUser: false });
      this.props.customerDetail(obj);
    } else if (order_id != "") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
    }
    if (time == 0) {
      // sessionStorage.setItem("callerId", callerId)
      this.props.StartClockTimer(time);
      this.props.stopCount();

      let callStatsObj = {
        call_status: 1,
        caller_id: callerId,
        customer_number: phone_number
      }
      this.props.saveCallrecord(callStatsObj);
    }
    this.setState({ currentPage: 1, pageSize: 10 })

    // can be use later
    // setTimeout(() => {
    //   window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    // }, 200);

  };

  submitPhoneTrace = (phone_number: any, order_id: any) => {
    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    let { traceOrderby } = this.state;
    // refined_phone_number = this.TrimNumber(phone_number);
    if (traceOrderby === 'Phone') {
      let obj: any = {
        phone_number: phone_number,
        // brand_id: this.props.brand && this.props.brand.brand_id,
        brand_id: 0,
        mode: "callcenter"
      };
      this.setState({ phone: phone_number, isValidPhone: true });
      this.setState({ newUser: false });
      this.props.customerDetail(obj);
    } else if (traceOrderby === "OrderId") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
    }

    this.setState({ currentPage: 1, pageSize: 10 })
  };

  isCustomerReady = () => {
    let {
      firstname,
      lastname,
      email,
      isValidEmail,
      delivery_address,
      houseNo
      // Area,
      // City,
    } = this.props;
    // let { newUserphone, city_id, zone_id, place, building, area } = this.state;
    let { newUserphone, city_id, zone_id, place, building, area } = this.state;
    if (email !== "") {
      return (
        firstname !== "" &&
        lastname !== "" &&
        isValidEmail &&
        newUserphone !== "" 
        // city_id !== "" &&
        // zone_id !== "" &&
        // area !== "" &&
        // building !== "" &&
        // place !== ""
        // Area !== "" &&
        // City !== ""
      );
    } else {
      return (
        firstname !== "" &&
        lastname !== "" &&
        newUserphone !== "" 
        // &&
        // city_id !== "" &&
        // zone_id !== "" &&
        // area !== "" &&
        // building !== "" &&
        // place !== ""
        // Area !== "" &&
        // City !== ""
      );
    }
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = (event: any) => {
    let token: any = localStorage.getItem('token');
    // let { building, city_id, place, zone_id, area, addressesList } = this.state
    let { building, city_id, place, zone_id, addressesList, area } = this.state
    let decoded: any = jwt.decode(token)
    localStorage.removeItem("flag")
    let { firstname, lastname, email } = this.props;
    let { newUserphone } = this.state;
    let data: any = {
      first_name: firstname,
      last_name: lastname,
      login_name: lastname === "" ? firstname : firstname + " " + lastname,
      full_name: lastname === "" ? firstname : firstname + " " + lastname,
      phone_number: newUserphone,
      geo_address:
        this.props.houseNo +
        " ",
      extra_details: building,
      // full_address:area + " " + building + " " + zone_id + " " + value,
      place: place,
      city_id: city_id,
      zone_id: zone_id,
      area_id: area,
      is_default: 0,
      street_name: this.props.streetName,
      street_number: this.props.streetNo,

      // this.props.Block +
      // " " +
      // this.props.Area +
      // " " +
      // this.props.City,
      // brand_id: decoded.brand.brand_id,
      brand_id: 1,
      is_active: 1,
      is_guest: 1,
    };
    if (email !== '') {
      data.email_address = email;
    }
    // console.log("add-data9999", data);
    this.props.addCustomer(data);
    // this.props.handleAddressInput(this.props.houseNo +  " " + this.props.tradeZoneName +" " + this.props.tradeAreaName + " " + this.props.cityName,)
    // this.setState({ newUser: false });
  };
  handleSubmitComplaint = (event: any) => {
    let { callduration } = this.props;
    const {
      fullname,
      email,
      phone,
      feedbackMessage,
      feedbackType,
      storeId,
      dateOfOrder,
      receiptnumber,
    } = this.state;
    const data: any = {
      name: fullname,
      email_address: email,
      phone_number: phone,
      receipt_number: receiptnumber,
      message: feedbackMessage,
      type: feedbackType,
      store_id: storeId,
      order_date: dateOfOrder,
    };
    callduration = callduration / 60;
    let rounded_time = Math.round(callduration * 10) / 10;
    let callStatsObj = {
      call_duration: rounded_time,
      call_status: 2,
      customer_number: localStorage.getItem("phone"),
      total_sale: 0,
      order_id: receiptnumber,
    };
    this.props.submitComplaint(data, callStatsObj);
    this.props.stopTimer();
  };
  isFormReady = () => {
    let { isValidPhone, phone, orderId } = this.state;
    return !isValidPhone || phone !== "" || orderId !== "";
  };

  handleAddressClick = (addressObj: any) => {
    if (addressObj) {
      this.setState({ selectedAddress: addressObj.address_id })
      addressObj.isClicked = true
      this.props.saveSelectedAddress(addressObj)
      this.setState({ building: addressObj.extra_details, place: addressObj.place, isUpdateBtnDisabled: false })
      this.props.setCityName(addressObj.city_name)
      this.props.getTradeZones(addressObj.city_id)
      this.props.setTradeAreaName(addressObj.area_name, addressObj.area_id)
      this.props.setTradeZoneName(addressObj.zone_name, addressObj.zone_id)
      this.props.getTradeAreas(addressObj.zone_id)
      this.props.setHouseNo(addressObj.extra_details)
      this.props.setStreetName(addressObj.street_name)
      this.props.setStreetNo(addressObj.street_number)
      // this.setState({ editable_fullAddress: addressObj.full_address, isUpdateBtnDisabled: false, editAddress: true })

    }
  }


  handleSearch = (e: any) => {
    this.setState({ googlesearch: e.target.value })
    axios.post(`${BASE_URL}customer/autoComplete/en/${e.target.value}`)
      .then((res: any) => {
        this.setState({ placesSuggestion: res.data.successResponse })
      })
      .catch((err: any) => {
        // alert("something went wrong!!!")
      })
  }

  handleEditSearch = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.addressesList;
    list[this.state.index]["full_address"] = value;
    this.setState({ addressesList: list });
    axios.post(`${BASE_URL}customer/autoComplete/en/${e.target.value}`)
      .then((res: any) => {
        this.setState({ placesSuggestion: res.data.successResponse })
      })
      .catch((err: any) => {
        // alert("something went wrong!!!")
      })
  }

  onPlaceClick = (place: any, index: any) => {
    const address = place.description
    this.setState({ googlesearch: address, placesSuggestion: [] })
    if (this.state.editAddress_id != '') {
      const list: any = this.state.addressesList;
      list[this.state.index]["full_address"] = address;
      // console.log("list new", this.state.index, list)
      this.setState({ addressesList: list });
    }

  };
  onCancelClick = (index: any) => {
    if (index == "add") {
      this.setState({ addAddress: false })
    }
    else {
      this.setState({ editAddress: false })
    }
  };


  handleZone = async (e: any) => {
    if (!_.isEmpty(e)) {
      let ee: any = JSON.parse(e.value)
      // if (this.props.tradeAreaName !== '') {

      //   this.selectRef3.select.clearValue();
      // }
      this.props.setTradeZoneName(e.label, e.value)
      this.props.setTradeAreaName('', null)
      this.props.getTradeAreas(ee.id)
      await this.setState({ zone_id: ee.id })
    }
    // this.props.setTradeAreaName('', null)
  }
  handleArea = async (e: any) => {
    if (!_.isEmpty(e)) {
      let area = JSON.parse(e.value)
      this.props.setTradeAreaName(e.label, e.value)
      await this.setState({ area: area.id })
    }

  }
  handleCityInput = async (e: any) => {
    if (!_.isEmpty(e)) {
      let ee: any = JSON.parse(e.value)
      if (this.props.tradeZoneName !== '') {
        this.selectRef2.select.clearValue();
      }
      // if (this.props.tradeAreaName !== '') {
      //   this.selectRef3.select.clearValue();
      // }
      this.props.setCityName(e.label)
      this.props.setTradeZoneName('', null)
      this.props.setTradeAreaName('', null)
      await this.setState({ city_id: ee.id })
      this.props.getTradeZones(ee.id)
    }
  }
  handleOrderRowClick = (row: any) => {
    this.setState({
      showDetails: true,
      selectedOrder: row,
    });
  }
  handleOrderRowClick1 = (row: any) => {
    this.setState({
      showDetails: false,
      selectedOrder: row,
    });
  }
  handleOrderRowCli1ck1 = (row: any) => {
    this.setState({ tableIndex: this.state.tableIndex + 1, showDetails: !this.state.showDetails });
  }

  onPageChange = (page: any, sizePerPage: any) => {
    toast.error(`page: ${page}, sizePerPage: ${sizePerPage}`)
    // this.setState({currentPage: page})
  }
  blockInvalidChar = (e: any) => {
    const regex = /^[0-9$@#&!]*$/;

    // Allow Ctrl + A (select all)
    if (e.ctrlKey && e.key.toLowerCase() === 'a') {
      return;
    }

    // Allow Ctrl + C (copy)
    if (e.ctrlKey && e.key.toLowerCase() === 'c') {
      return;
    }
    // Allow Ctrl + X (cut)
    if (e.ctrlKey && e.key.toLowerCase() === 'x') {
      return;
    }

    // Check if the event is a paste event (Ctrl + V)
    if (e.ctrlKey && e.key.toLowerCase() === 'v') {
      let pastedText;
      // Handle clipboard data in a cross-browser compatible way
      if (e.clipboardData) {
        // For modern browsers
        pastedText = e.clipboardData.getData('text');
      } else if (e.originalEvent && e.originalEvent.clipboardData) {
        // For older browsers
        pastedText = e.originalEvent.clipboardData.getData('text');
      } else {
        // Fallback if clipboardData is not available
        pastedText = '';
      }

      // Check if the pasted text contains any invalid characters
      if (!regex.test(pastedText)) {
        e.preventDefault(); // Prevent pasting if not matching regex
      }
      return;
    }

    // Allow numbers (including number pad) and specified special keys
    const specialKeys = [8, 9]; // Backspace and Tab
    if (
      !specialKeys.includes(e.keyCode) &&
      !regex.test(e.key) &&
      e.key !== ' '
    ) {
      e.preventDefault(); // Prevent input if not matching regex or if not a special key
    }
  };
  imageDescription = (indexNumber: any) => {
    let { brands } = this.props;
    if (brands.length > 0) {
      const brandAtIndex = brands[indexNumber];
      if (brandAtIndex) {
        return API_URL + brandAtIndex.landing_image;
      } else {
        return process.env.PUBLIC_URL + "/assets/img/ky.png";
      }
    } else {
      return process.env.PUBLIC_URL + "/assets/img/ky.png";
    }
  };
  brandFormatter(cell: any, row: any, props: any) {
    return <img className="img-fluid mr-auto" width={`200px`} height={`100px`} src={this.imageDescription(row)} />;
  }
  genesysCustomerData(data?: any) {
    this.props.customerDetail(data);
  }
  getColumns = (props: any) => {
    return [
      {
        dataField: 'order_id',
        text: 'Order Id',
      },
      {
        dataField: 'store_name',
        text: 'Store',
      },
      {
        dataField: 'login_name',
        text: 'Name',
      },
      {
        dataField: 'phone_number',
        text: 'Phone',
      },
      {
        dataField: 'order_status_description',
        text: 'Order Status',
      },
      {
        dataField: 'date_created',
        text: 'Order Received',
        formatter: dateFormatterData,
      },
      {
        dataField: 'details',
        text: 'Details',
        formatter: actionFormatter,
        formatExtraData: props,
      },
      {
        dataField: 'action',
        text: 'Action',
        formatter: complaintFormatter,
        formatExtraData: props,
      },
    ];
  };

  expandRow = {
    parentClassName: 'parent-expand-foo',
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }: any) => {
      if (isAnyExpands) {
        return <b className="cursor-pointer">-</b>;
      }
      return <b className="cursor-pointer">+</b>;
    },
    expandColumnRenderer: ({ expanded }: any) => {
      if (expanded) {
        return (
          <b className="errow--err">▲</b>
        );
      }
      return (
        <b className="errow--err">▼</b>
      );
    },
    renderer: (row: any, cell: any,) => {

      return (
        <>
          <div className="row table-box">
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5> Order Id:</h5>
                <h5>{row.order_id}</h5>
              </div>
            </div>
            {/* <div className="col-lg-6">
          <div className="dom-table-style">
            <h5>Brand:</h5>
            {this.brandFormatter(row.id, cell, this.props)}
          </div>
        </div> */}
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Store:</h5>
                <h5>{row.store_name}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Order Mode:</h5>
                <h5>{row.delivery_status}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Order Channel:</h5>
                <h5>{row.order_channel}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Name:</h5>
                <h5>{row.login_name}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Phone:</h5>
                <h5>{row.phone_number}</h5>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Order Price:</h5>
                <h5>{priceunit}{" "}{row.order_grossprice}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Payment Method:</h5>
                <h5>{row.payment_method}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Order Status:</h5>
                <h5>{row.order_status_description}</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Order Received:</h5>
                <h5>{formatDateTime(row.date_created)}</h5>
              </div>
            </div>
            {row.channel == "delivery" && <div className="col-lg-6">
              <div className="dom-table-style">
                <h5>Address:</h5>
                <h5>{row.delivery_address}</h5>
              </div>
            </div>}
          </div>
        </>
      )
    }

  };
  render() {
    const columns = this.getColumns(this.props);
    let flag = sessionStorage.getItem("flag");
    // const { phone } = this.state;
    let { isValidEmail, email } = this.props;
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      localStorage.setItem("urlPhone", this.customerNumber);
      return <Redirect to="/" />;
    }
    let { orders, customer, stores, storeData, addressData, brands } = this.props;
    // console.log("customer-email999", customer);
    let { newUser, sizejson, addressesList } = this.state;
    if (addressesList && addressesList.length > 0) {
      addressesList.map((address: any, index: any) => {
        localStorage.setItem("address_id", address.address_id);
        return true;
      })
    }
    const options: any = {
      sizePerPage: this.state.pageSize,
      page: this.state.currentPage,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      onPageChange: (page: number, sizePerPage: number) => {
        // Update the state with the new page number and size per page
        this.setState({
          currentPage: page,
          pageSize: sizePerPage
        });
      },
      noDataText: "Orders Not Found",
    };

    let cities_arr: any = []
    let zones_arr: any = []
    let areas_arr: any = []
    this.props.cities_portal && this.props.cities_portal.map((city: any) => {
      cities_arr.push({ label: city.name, value: JSON.stringify(city) })
    })

    this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
      zones_arr.push({ label: zone.delivery_zone_name, value: JSON.stringify(zone) })
    })

    this.props.tradeAreas_portal && this.props.tradeAreas_portal.map((area: any) => {
      areas_arr.push({ label: area.area_name, value: JSON.stringify(area) })
    })
    const { phone } = this.state;
    // const phoneData = this.props.customerPhoneData;
    // const initialPhoneNumber = phoneData !== null ? phoneData : '';
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-2">
                  <h4 className="mt-2">Customers Management</h4>
                  {/* <div>
                  <button className="btn btn-danger mr-3" style={{ padding: '0 15px' }} onClick={()=> this.submitPhoneTrace(this.state.phone,this.state.orderId)} ><i className="fa fa-refresh"></i></button>
                  </div> */}
                </div>
              </div>
            </header>
            {/* <div className="breadcrumb-holder container-fluid"> */}
            {/* <ul className="breadcrumb"> */}
            <section className="tables mt-4 no-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card mb-1">
                      <div className="card-body p-3">
                        <Tabs>
                          <TabLink
                            activeClassName="btn btn-danger"
                            className="btn w-50 border-theme"
                            to="tab1"
                            onClick={() => {
                              this.setState({ traceOrderby: "other" });
                            }}
                          >
                            New Order
                          </TabLink>
                          <TabLink
                            activeClassName="btn btn-danger"
                            className="btn w-50 border-theme"
                            to="tab2"
                          >
                            Track Order
                          </TabLink>
                          {/* <TabLink activeClassName="btn btn-danger" className='btn' to="tab3">Register Feedback</TabLink> */}

                          <TabContent className="mt-4" for="tab1">
                            <div className="form-group row mb-1 align-items-start d-flex">
                              <label className="col-sm-12 form-control-label white-space input-title">
                                Enter Phone Number (03XXXXXXXXX)
                              </label>
                              <div className="col-sm-12">
                                <div className="input-group">
                                  {/* <span className="input-group-text">+92</span> */}
                                  <input
                                    id="inputHorizontalSuccess"
                                    value={phone ? phone : ""}
                                    type="tel"
                                    maxLength={11}
                                    name="phone"
                                    required
                                    placeholder="Phone Number"
                                    className="form-control form-control-success"
                                    onKeyDown={this.blockInvalidChar}
                                    onChange={this.validatePhone}
                                  />
                                </div>

                                {this.state.isValidPhone === false &&
                                  this.state.phone !== "" && (
                                    <small className="form-text text-danger">
                                      Invalid Phone Number
                                    </small>
                                  )}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div className="mb-0 mt-2">

                                {this.props.customer && this.props.customer.is_delete == 1 &&
                                  <div>
                                    <button className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleActivation} >
                                      Activate Account
                                    </button>
                                  </div>
                                }
                                {this.props.customer && (this.props.customer.is_active == 1 && (this.props.customer.is_delete == 0 || !this.props.customer.is_delete)) ?
                                  <div>
                                    <button
                                      disabled={!this.state.isValidPhone || this.state.phone === ""}
                                      className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleBlockCustomer} >
                                      Block
                                    </button>
                                  </div> :
                                  this.props.customer && (this.props.customer.is_active == 0 && (this.props.customer.is_delete == 0 || !this.props.customer.is_delete)) &&
                                  <div>
                                    <button
                                      disabled={this.state.phone.length >= 11 ? false : true}
                                      className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleUnBlockCustomer} >
                                      Unblock
                                    </button>
                                  </div>
                                }
                                {flag && _.isEmpty(this.props.customer) && <button
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  disabled={
                                    (
                                      this.state.phone?.length == 11) &&
                                      flag
                                      ? false
                                      : true
                                  }
                                  data-target={`#blockunblockk`}
                                  onClick={() => {
                                    this.setState({
                                      building: "",
                                      place: "",
                                    })
                                  }}
                                >
                                  Add Customer
                                </button>}
                              </div>
                              <div className="mb-0 mt-2">
                                <input
                                  type="submit"
                                  disabled={!this.state.isValidPhone || this.state.phone === ""}
                                  value="Submit"
                                  onClick={() => {
                                    this.submitPhone(
                                      this.state.phone,
                                      this.state.orderId
                                    );
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                            </div>
                          </TabContent>

                          <TabContent className="mt-4" for="tab2">
                            <div className="form-group row">
                              <div className="col-12 d-flex  align-items-start  ">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={this.state.traceOrderby === "Phone"}
                                  value="Phone"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "Phone" });
                                  }}
                                  name="Phone"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Phone Number
                                </label>
                              </div>
                              <div className="col-12 d-flex  align-items-start">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={
                                    this.state.traceOrderby === "OrderId"
                                  }
                                  value="OrderId"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "OrderId" });
                                  }}
                                  name="OrderId"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Order ID
                                </label>
                              </div>
                            </div>

                            {this.state.traceOrderby === "Phone" && (
                              <div className="form-group row">
                                <label className="col-sm-12 form-control-label">
                                  Track Order By Phone Number:
                                </label>
                                <div className="col-sm-12">
                                  <div className="input-group">
                                    {/* <span className="input-group-text">
                                      +27
                                    </span> */}
                                    <input
                                      id="inputHorizontalSuccess"
                                      type="tel"
                                      maxLength={11}
                                      name="phone"
                                      required
                                      placeholder="Phone Number"
                                      className="form-control form-control-success"
                                      onChange={this.validatePhone}
                                    />
                                  </div>

                                  {this.state.isValidPhone === false &&
                                    this.state.phone !== "" && (
                                      <small className="form-text text-danger">
                                        Invalid Phone Number
                                      </small>
                                    )}
                                </div>
                              </div>
                            )}
                            {this.state.traceOrderby === "OrderId" && (
                              <div className="form-group row">
                                <label className="col-sm-12 form-control-label">
                                  Track Order By Order Id:
                                </label>
                                <div className="col-sm-12">
                                  <input
                                    id="inputHorizontalSuccess"
                                    type="tel"
                                    maxLength={11}
                                    name="orderId"
                                    required
                                    placeholder="Order Id"
                                    className="form-control form-control-success"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="d-flex justify-content-between">
                              <div className="form-group">
                                {/* <input
                                  type="submit"
                                  value="Add Customer"
                                  onClick={() => {
                                    this.setState({ newUser: true });
                                  }}
                                  className="btn btn-danger"
                                /> */}
                                <button
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  disabled={
                                    (
                                      this.state.phone?.length == 11) &&
                                      flag
                                      ? false
                                      : true
                                  }
                                  data-target={`#blockunblockk`}
                                >
                                  Add Customer
                                </button>
                              </div>
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Submit"
                                  disabled={!this.isFormReady()}
                                  onClick={() => {
                                    this.submitPhoneTrace(
                                      this.state.phone,
                                      this.state.orderId
                                    );
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                            </div>
                          </TabContent>
                          <TabContent className="mt-4" for="tab3">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="panel panel-default">
                                    <div className="panel-body">
                                      <div className="col-md-12">
                                        <div className="form-row">
                                          <div className="form-group col-md-12">
                                            {" "}
                                            <br />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Full Name{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="fullname"
                                              placeholder="Full Name"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Phone{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="phone"
                                              placeholder="Phone"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Email{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="email"
                                              placeholder="Email"
                                              style={{ fontSize: "15px" }}
                                              type="email"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Select Ordering Store{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>

                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="storeId"
                                            >
                                              <option value="">
                                                Select Store
                                              </option>

                                              {stores &&
                                                stores.map(
                                                  (store: any, index: any) => (
                                                    <option
                                                      key={index}
                                                      value={store.store_id}
                                                    >
                                                      {store.store_name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Order Date{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>{" "}
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              type="date"
                                              name="dateOfOrder"
                                              required
                                              data-msg="Please Enter Order Date"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Order ID{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="receiptnumber"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              placeholder="Enter receipt number"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              {" "}
                                              Feedback Type{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="feedbackType"
                                            >
                                              <option value="">
                                                Select Feedback Type
                                              </option>
                                              <option value="Comments">
                                                Comment
                                              </option>
                                              <option value="Suggestions">
                                                Suggestion
                                              </option>
                                              <option value="Complaint">
                                                Complaint
                                              </option>
                                              <option value="Questions">
                                                Question
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              Feedback{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <textarea
                                              onChange={this.handleInputChange}
                                              className="form-control"
                                              name="feedbackMessage"
                                              id="round2"
                                              rows={4}
                                              cols={120}
                                            ></textarea>
                                          </div>
                                          <div className="form-group col-md-4">
                                            <button
                                              onClick={
                                                this.handleSubmitComplaint
                                              }
                                              id="h"
                                              style={{
                                                borderRadius: "10px",
                                                backgroundColor: "#c00a27",
                                                color: "white",
                                                borderColor: "#c00a27",
                                              }}
                                              type="submit"
                                              className="btn btn-primary"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabContent>
                        </Tabs>
                      </div>
                    </div>
                    <div
                      id="blockunblockk"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      className="modal fade text-left"
                    >
                      <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h3>Add Customer Data</h3>
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="Close"
                              className="close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>

                          <div>
                            <section className="forms pt-3">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="card mb-0">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col">
                                            <div className="form-group">
                                              <label className="form-control-label">
                                                First name{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                id="firstname"
                                                type="text"
                                                name="firstname"
                                                required
                                                placeholder="Please Enter First Name"
                                                data-msg="Please enter First Name"
                                                className="form-control"
                                                value={this.props.firstname}
                                                onChange={
                                                  this.props.handleCustomerInput
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col">
                                            <div className="form-group">
                                              <label className="form-control-label">
                                                Last Name{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                id="lastname"
                                                type="text"
                                                name="lastname"
                                                required
                                                placeholder="Please Enter Last Name"
                                                data-msg="Please enter Last Name"
                                                className="form-control"
                                                value={this.props.lastname}
                                                onChange={
                                                  this.props.handleCustomerInput
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label">
                                                Phone{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <div className="input-group">
                                                {/* <span className="input-group-text">+92</span> */}
                                                <input
                                                  id="phone"
                                                  type="tel"
                                                  maxLength={11}
                                                  name="newUserphone"
                                                  defaultValue={
                                                    this.state.newUserphone
                                                  }
                                                  required
                                                  placeholder="Please Enter Phone"
                                                  data-msg="Please enter Phone"
                                                  className="form-control form-control-success"
                                                  disabled={this.state.phone !== ""}
                                                  onChange={this.validatePhone}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label">
                                                Email
                                              </label>
                                              <input
                                                id="email"
                                                type="text"
                                                name="email"
                                                required
                                                placeholder="Please Enter Email"
                                                data-msg="Please enter Email"
                                                className="form-control"
                                                value={this.props.email}
                                                onChange={
                                                  this.props.validateEmail
                                                }
                                              />
                                              {isValidEmail == false &&
                                                email !== "" && (
                                                  <small className="form-text text-danger">
                                                    email is not correct
                                                  </small>
                                                )}
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label label-margin">
                                                City{" "}
                                              </label>
                                              {/* <span className="text-danger">
                                                *
                                              </span> */}
                                              <Select
                                                name="cities"
                                                value={{
                                                  label: this.props.cityName
                                                    ? this.props.cityName
                                                    : "Select your city",
                                                  value: "Select...",
                                                }}
                                                options={cities_arr}
                                                className="text-capitalize basic-multi-select mt-2"
                                                classNamePrefix="Select your city"
                                                isLoading={
                                                  cities_arr.length == 0
                                                    ? true
                                                    : false
                                                }
                                                onChange={this.handleCityInput}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label label-margin">
                                                Town / Area / Phase / Block / Society{" "}
                                              </label>
                                              {/* <span className="text-danger">
                                                *
                                              </span> */}
                                              <Select
                                                ref={(ref) => {
                                                  this.selectRef2 = ref;
                                                }}
                                                name="zones"
                                                value={{
                                                  label: this.props
                                                    .tradeZoneName
                                                    ? this.props.tradeZoneName
                                                    : "Select your zone",
                                                  value: "",
                                                }}
                                                isDisabled={zones_arr.length == 0 ? true : false}
                                                options={
                                                  this.props.cityName
                                                    ? zones_arr
                                                    : []
                                                }
                                                className="text-capitalize basic-multi-select mt-2"
                                                classNamePrefix="Select your zone"
                                                onChange={this.handleZone}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label label-margin">
                                                Sector / Street Name / Number{" "}
                                              </label>
                                              {/* <span className="text-danger">
                                                *
                                              </span> */}
                                              <Select
                                                ref={(ref) => {
                                                  this.selectRef3 = ref;
                                                }}
                                                isDisabled={areas_arr.length == 0 ? true : false}
                                                value={{
                                                  label: this.props
                                                    .tradeAreaName
                                                    ? this.props.tradeAreaName
                                                    : "Select your area",
                                                  value: "",
                                                }}
                                                name="areas"
                                                options={
                                                  this.props.tradeZoneName != ""
                                                    ? areas_arr
                                                    : []
                                                }
                                                className="text-capitalize basic-multi-select mt-2"
                                                classNamePrefix="Select your area"
                                                onChange={this.handleArea}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label">
                                                Flat / House / Office Number{" "}{" "}
                                              </label>
                                              {/* <span className="text-danger">
                                                *
                                              </span> */}
                                              <input

                                                className="form-control"

                                                name="houseNo"
                                                // onKeyDown={this.blockInvalidChar}
                                                value={this.state.building}
                                                onChange={(e) => {
                                                  this.setState({
                                                    building: e.target.value,
                                                  });
                                                }}
                                                placeholder="Enter Extra Details"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label label-margin">
                                                Street Name{" "}
                                              </label>
                                              {/* <span className="text-danger">*</span> */}
                                              <input
                                                id="streetName"
                                                type="text"
                                                name="streetName"
                                                placeholder="Enter Street Name"
                                                required
                                                data-msg="Please enter Street Name"
                                                className="form-control"
                                                value={this.props.streetName}
                                                onChange={(e) => this.props.setStreetName(e.target.value)}
                                                onPaste={(e) => { e.preventDefault() }}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="form-control-label label-margin">
                                                Street Number{" "}
                                              </label>
                                              {/* <span className="text-danger">*</span> */}
                                              <input
                                                id="streetNo"
                                                type="text"
                                                name="streetNo"
                                                placeholder="Enter Street No"
                                                required
                                                data-msg="Please enter Street No"
                                                className="form-control"
                                                value={this.props.streetNo}
                                                onChange={(e) => this.props.setStreetNo(e.target.value)}
                                                onKeyDown={this.blockInvalidChar}
                                                onPaste={(e) => { e.preventDefault() }}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <label className="form-control-label pb-1">
                                              Name of this location{" "}
                                            </label>
                                            {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            <select
                                              name="place"
                                              onChange={(e) =>
                                                this.handleNewVariantInputChange(
                                                  e,
                                                  3
                                                )
                                              }
                                              className="form-control mt-2"
                                              required
                                              data-msg="Please select address type"
                                              value={this.state.place}
                                            >
                                              <option selected value="">
                                                Select Your Location Name
                                              </option>
                                              <option key={"home"} value="Home">
                                                Home
                                              </option>
                                              <option
                                                key={"office"}
                                                value="Office"
                                              >
                                                Office
                                              </option>
                                              <option
                                                key={"other"}
                                                value="Other"
                                              >
                                                {" "}
                                                Other
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="form-group d-flex justify-content-end mt-4">
                                          <button
                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-danger"
                                            disabled={!this.isCustomerReady()}
                                            onClick={this.handleSubmit}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!_.isEmpty(customer) &&
                      this.state.traceOrderby != "OrderId" && (
                        <div className="">
                          <div className="row">
                            <section className="col-lg-12 pt-2 pb-2">
                              <div id="accordion">
                              <div
                                className="card-header no-padding"
                                id="headingOne"
                              >
                                <h5 className="mb-0">
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn btn-link"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Customer Information
                                    </button>
                                    <div style={{ padding: "10px 20px" }}>
                                      <Link to="/menu">
                                        <button className="btn btn-danger">
                                          Start Order
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                </h5>
                              </div>

                              <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingOne"
                                data-parent="#accordion"
                              >
                                <div className="project bg-theme-box has-shadow">
                                  <div className="card-body p-0">
                                    <div className="">
                                      <form>
                                        <div className="form-group row mb-0">
                                          <div className="col-lg-6 border-0">
                                            <label className="form-control-label">
                                              Name
                                            </label>
                                            <input
                                              type="text"
                                              disabled
                                              value={
                                                customer &&
                                                customer.login_name
                                              }
                                              placeholder="Customer Name"
                                              className="form-control custom-height-input"
                                            />
                                          </div>
                                          <div className="col-lg-6 border-0">
                                            <label className="form-control-label">
                                              Phone
                                            </label>
                                            <input
                                              type="phone"
                                              disabled
                                              value={
                                                customer &&
                                                customer.phone_number
                                              }
                                              placeholder="Phone Number"
                                              className="form-control custom-height-input"
                                            />
                                          </div>
                                          <div className="col-lg-12 border-0 pt-2">
                                            <label className="form-control-label">
                                              Email
                                            </label>
                                            <input
                                              type="email"
                                              disabled
                                              value={
                                                customer &&
                                                customer.email_address
                                              }
                                              placeholder="Email Address"
                                              className="form-control custom-height-input"
                                            />
                                          </div>
                                          {this.state.editAddress && (
                                            <div
                                              className="col-lg-12 border-0 pt-2"
                                              style={{
                                                maxHeight: "350px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              <label className="form-control-label">
                                                Selected Address
                                              </label>
                                              <input
                                                autoComplete="off"
                                                style={{
                                                  height:
                                                    "calc(1.8125rem + 10px)",
                                                  borderRadius: "5px",
                                                }}
                                                name="full_address"
                                                className="form-control search-wrapper custom-height-input"
                                                value={`${(this.state.building
                                                  ? this.state.building
                                                  : "") +
                                                  " " +
                                                  (this.props.tradeAreaName
                                                    ? this.props.tradeAreaName
                                                    : "") +
                                                  " " +
                                                  (this.props.tradeZoneName
                                                    ? this.props.tradeZoneName
                                                    : "") +
                                                  ", " +
                                                  (this.props.cityName
                                                    ? this.props.cityName
                                                    : "")
                                                  }`}
                                                // disabled={address.enable ? !address.enable : true}
                                                disabled={true}
                                                placeholder="Enter your full address"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div></div>
                                        <div
                                          style={{
                                            maxHeight: "350px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {addressesList &&
                                            this.state.addAddress != true &&
                                            this.state.editAddress != true &&
                                            addressesList.length > 0 ? (
                                            addressesList.map(
                                              (address: any, ind: any) => {
                                                return (
                                                  <>
                                                    <div
                                                      key={ind}
                                                      className="form-group row no-padding ml-3 d-block"
                                                    >
                                                      <label className="form-control-label">
                                                        Delivery Address
                                                      </label>
                                                      <div className="col">
                                                        <div className="d-flex">
                                                          <input
                                                            style={{
                                                              height: "25px",
                                                              transform:
                                                                "scale(1.5)",
                                                            }}
                                                            className="form-check-input"
                                                            onChange={() =>
                                                              this.handleAddressClick(
                                                                address
                                                              )
                                                            }
                                                            checked={
                                                              address.address_id === this.state.selectedAddress
                                                            }
                                                            type="radio"
                                                            name="delivery_address"
                                                            id={
                                                              "delivery_address" +
                                                              ind
                                                            }
                                                          />

                                                          <input
                                                            autoComplete="off"
                                                            style={{
                                                              height:
                                                                "calc(1.8125rem + 10px)",
                                                              borderRadius:
                                                                "5px",
                                                            }}
                                                            name="full_address"
                                                            className="form-control search-wrapper custom-height-input"
                                                            value={
                                                              `${(address.extra_details !=
                                                                null
                                                                ? address.extra_details
                                                                : "") +
                                                              (address.extra_details !=
                                                                null
                                                                ? ","
                                                                : "") +
                                                              " " +
                                                              (address.area_id &&
                                                                address.area_name) +
                                                              " " +
                                                              (address.zone_id &&
                                                                address.zone_name) +
                                                              " " +
                                                              (address.city_id &&
                                                                address.city_name)
                                                              }`
                                                              //  !address.enable ? address.full_address : this.state.editable_fullAddress
                                                            }
                                                            // disabled={address.enable ? !address.enable : true}
                                                            disabled={true}
                                                            onChange={(
                                                              e: any
                                                            ) =>
                                                              this.handleEditSearch(
                                                                e,
                                                                ind
                                                              )
                                                            }
                                                            placeholder="Enter your full address"
                                                          />

                                                          <button
                                                            className="btn btn-sm btn-primary ml-2"
                                                            type="button"
                                                            onClick={() =>
                                                              this.editAddressField(
                                                                address,
                                                                ind
                                                              )
                                                            }
                                                          >
                                                            <i className="fa fa-edit"></i>
                                                          </button>
                                                          {addressesList.length -
                                                            1 ===
                                                            ind && (
                                                              <button
                                                                className="btn btn-sm btn-primary ml-2"
                                                                onClick={(
                                                                  e: any
                                                                ) =>
                                                                  this.handleAddClick(
                                                                    e,
                                                                    ind
                                                                  )
                                                                }
                                                              >
                                                                <i className="fa fa-plus"></i>
                                                              </button>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="form-group mb-0 pb-2">
                                                      <div className="col-6">
                                                        <select
                                                          name="place"
                                                          disabled={true}
                                                          onChange={(e) =>
                                                            this.handleVariantInputChange(
                                                              e,
                                                              ind
                                                            )
                                                          }
                                                          style={{ paddingTop: '3px' }}
                                                          className="form-control custom-height-input"
                                                          required
                                                          data-msg="Please select address type"
                                                        >
                                                          <option
                                                            key={"home" + ind}
                                                            value="Home"
                                                            {...(address.place ==
                                                              "Home" && {
                                                              selected: true,
                                                            })}
                                                          >
                                                            Home
                                                          </option>
                                                          <option
                                                            key={
                                                              "office" + ind
                                                            }
                                                            value="Office"
                                                            {...(address.place ==
                                                              "Office" && {
                                                              selected: true,
                                                            })}
                                                          >
                                                            Office
                                                          </option>
                                                          <option
                                                            key={
                                                              "other" + ind
                                                            }
                                                            value="Other"
                                                            {...(address.place ==
                                                              "Other" && {
                                                              selected: true,
                                                            })}
                                                          >
                                                            {" "}
                                                            Other
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                              )
                                          ) : (
                                            <>
                                              {sizejson.length === 0 &&
                                                  !this.state.editAddress && (
                                                    <div className="row py-0">
                                                      <div className="col">
                                                      <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={(e: any) =>
                                                          this.handleAddClick(
                                                            e
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-plus"></i>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  )}
                                              </>
                                          )}
                                        </div>
                                        {this.state.addAddress &&
                                          !this.state.editAddress && (
                                            <>
                                            <div
                                              style={{ padding: "unset" }}
                                              className="row mb-0"
                                            >
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label">
                                                    City{" "}
                                                  </label>
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                  <Select
                                                    name="cities"
                                                    value={{
                                                      label: this.props
                                                        .cityName
                                                        ? this.props
                                                          .cityName
                                                        : "Select...",
                                                      value: "Select...",
                                                    }}
                                                    options={cities_arr}
                                                    className="text-capitalize basic-multi-select mt-2"
                                                    classNamePrefix="Select your city"
                                                    isLoading={
                                                      cities_arr.length == 0
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={
                                                      this.handleCityInput
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label">
                                                    Town / Area / Phase / Block / Society{" "}
                                                    <span className="text-danger">
                                                    *
                                                    </span>
                                                  </label>
                                                  <Select
                                                    ref={(ref) => {
                                                      this.selectRef2 = ref;
                                                    }}
                                                    name="zones"
                                                    value={{
                                                      label: this.props
                                                        .tradeZoneName
                                                        ? this.props
                                                          .tradeZoneName
                                                        : "Select...",
                                                      value: "",
                                                    }}
                                                    options={
                                                      this.props.cityName
                                                        ? zones_arr
                                                        : []
                                                    }
                                                    className="text-capitalize basic-multi-select mt-2"
                                                    classNamePrefix="Select your zone"
                                                    isDisabled={zones_arr.length == 0 ? true : false}
                                                    onChange={
                                                      this.handleZone
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label label-margin">
                                                    Sector / Street Name / Number{" "}
                                                    <span className="text-danger">*</span>
                                                  </label>
                                                  <Select
                                                    ref={(ref) => {
                                                      this.selectRef3 = ref;
                                                    }}
                                                    value={{
                                                      label: this.props
                                                        .tradeAreaName
                                                        ? this.props
                                                          .tradeAreaName
                                                        : "Select...",
                                                      value: "",
                                                    }}
                                                    name="areas"
                                                    options={
                                                      this.props
                                                        .tradeZoneName != ""
                                                        ? areas_arr
                                                        : []
                                                    }
                                                    className="text-capitalize basic-multi-select mt-2"
                                                    classNamePrefix="Select your area"
                                                    isDisabled={areas_arr.length == 0 ? true : false}
                                                    onChange={
                                                      this.handleArea
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col">
                                                <div className="form-group">
                                                  <label className="form-control-label label-margin">
                                                    Flat / House / Office Number{" "}{" "}
                                                    <span className="text-danger">*</span>
                                                  </label>
                                                  <input
                                                    style={{
                                                      marginTop: "10px",
                                                      borderRadius: 5,
                                                      height: "38px",
                                                      border:
                                                        "1px solid rgb(204,204,204)",
                                                    }}
                                                    className="form-control custom-height-input"
                                                    name="houseNo"
                                                    value={
                                                      this.state.building
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        building:
                                                          e.target.value,
                                                      });
                                                    }}
                                                    placeholder="Please enter House Number"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label label-margin">
                                                    Street Name{" "}
                                                  </label>
                                                  <input
                                                    style={{
                                                      marginTop: "10px",
                                                      borderRadius: 5,
                                                      height: "38px",
                                                      border:
                                                        "1px solid rgb(204,204,204)",
                                                    }}
                                                    id="streetName"
                                                    type="text"
                                                    name="streetName"
                                                    placeholder={"Please enter Street Name"}
                                                    required
                                                    data-msg="Please enter Street Name"
                                                    className="form-control custom-height-input"
                                                    onChange={(e) => this.props.setStreetName(e.target.value)}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label label-margin">
                                                    Street Number{" "}
                                                  </label>
                                                  <input
                                                    style={{
                                                      marginTop: "10px",
                                                      borderRadius: 5,
                                                      height: "38px",
                                                      border:
                                                        "1px solid rgb(204,204,204)",
                                                    }}
                                                    id="streetNo"
                                                    type="text"
                                                    name="streetNo"
                                                    placeholder={"Please enter Street No"}
                                                    required
                                                    data-msg="Please enter Street No"
                                                    className="form-control custom-height-input"
                                                    onChange={(e) => this.props.setStreetNo(e.target.value)}
                                                    onKeyDown={this.blockInvalidChar}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                  />
                                                </div>
                                              </div>
                                              </div>

                                              <div className="form-group row mb-0">
                                                <div
                                                  className="col-6"
                                                  style={{
                                                    marginBottom: "unset",
                                                  }}
                                                >
                                                  <select
                                                    name="place"
                                                    onChange={(e) =>
                                                      this.handleNewVariantInputChange(
                                                        e,
                                                        1
                                                      )
                                                    }
                                                    className="form-control mt-2"
                                                    required
                                                    data-msg="Please select address type"
                                                  >
                                                    <option
                                                      key={"home" + 1}
                                                      value="Home"
                                                    >
                                                      Home
                                                    </option>
                                                    <option
                                                      key={"office" + 1}
                                                      value="Office"
                                                    >
                                                      Office
                                                    </option>
                                                    <option
                                                      key={"other" + 1}
                                                      value="Other"
                                                    >
                                                      {" "}
                                                      Other
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        {this.state.editAddress &&
                                          !this.state.addAddress && (
                                            <>
                                              <div
                                                style={{ padding: "unset" }}
                                                className="row mb-0"
                                              >

                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <label className="form-control-label">
                                                      City{" "}
                                                    </label>
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                    <Select
                                                      name="cities"
                                                      value={{
                                                        label: this.props
                                                          .cityName
                                                          ? this.props
                                                            .cityName
                                                          : "Select...",
                                                        value: "Select...",
                                                      }}
                                                      options={cities_arr}
                                                      className="text-capitalize basic-multi-select mt-2"
                                                      classNamePrefix="Select your city"
                                                      isLoading={
                                                        cities_arr.length == 0
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={
                                                        this.handleCityInput
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <label className="form-control-label">
                                                      Town / Area / Phase / Block / Society{" "}
                                                    </label>
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                    <Select
                                                      ref={(ref) => {
                                                        this.selectRef2 = ref;
                                                      }}
                                                      name="zones"
                                                      value={{
                                                        label: this.props
                                                          .tradeZoneName
                                                          ? this.props
                                                            .tradeZoneName
                                                          : "Select...",
                                                        value: "",
                                                      }}
                                                      options={
                                                        this.props.cityName
                                                          ? zones_arr
                                                          : []
                                                      }
                                                      className="text-capitalize basic-multi-select mt-2"
                                                      classNamePrefix="Select your zone"
                                                      isLoading={
                                                        zones_arr.length == 0
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={
                                                        this.handleZone
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <label className="form-control-label label-margin">
                                                      Sector / Street Name / Number{" "}
                                                    </label>
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                    <Select
                                                      ref={(ref) => {
                                                        this.selectRef3 = ref;
                                                      }}
                                                      value={{
                                                        label: this.props
                                                          .tradeAreaName
                                                          ? this.props
                                                            .tradeAreaName
                                                          : "Select...",
                                                        value: "",
                                                      }}
                                                      name="areas"
                                                      options={
                                                        this.props
                                                          .tradeZoneName != ""
                                                          ? areas_arr
                                                          : []
                                                      }
                                                      className="text-capitalize basic-multi-select mt-2"
                                                      classNamePrefix="Select your area"
                                                      isLoading={
                                                        areas_arr.length == 0
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={
                                                        this.handleArea
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col">
                                                  <div className="form-group">
                                                    <label className="form-control-label label-margin">
                                                      Flat / House / Office Number{" "}{" "}
                                                    </label>
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                    <input
                                                      style={{
                                                        marginTop: "10px",
                                                        borderRadius: 5,
                                                        height: "38px",
                                                        border:
                                                          "1px solid rgb(204,204,204)",
                                                      }}
                                                      className="form-control custom-height-input"
                                                      name="houseNo"

                                                      value={
                                                        this.state.building
                                                      }
                                                      onChange={(e) => {
                                                        this.setState({
                                                          building:
                                                            e.target.value,
                                                        });
                                                      }}
                                                      placeholder="Enter Extra Details"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <label className="form-control-label label-margin">
                                                    Street Name{" "}
                                                  </label>
                                                  {/* <span className="text-danger">*</span> */}
                                                  <input
                                                    style={{
                                                      marginTop: "10px",
                                                      borderRadius: 5,
                                                      height: "38px",
                                                      border:
                                                        "1px solid rgb(204,204,204)",
                                                    }}
                                                    id="streetName"
                                                    type="text"
                                                    name="streetName"
                                                    placeholder={this.props.streetName ? this.props.streetName : ""}
                                                    required
                                                    data-msg="Please enter Street Name"
                                                    value={this.props.streetName}
                                                    className="form-control custom-height-input"
                                                    onChange={(e) => this.props.setStreetName(e.target.value)}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <div className="form-group">
                                                  <label className="form-control-label label-margin">
                                                    Street Number{" "}
                                                  </label>
                                                  <input
                                                    style={{
                                                      marginTop: "10px",
                                                      borderRadius: 5,
                                                      height: "38px",
                                                      border:
                                                        "1px solid rgb(204,204,204)",
                                                    }}
                                                    id="streetNo"
                                                    type="text"
                                                    name="streetNo"
                                                    placeholder={this.props.streetNo ? this.props.streetNo : ""}
                                                    required
                                                    value={this.props.streetNo}
                                                    data-msg="Please enter Street No"
                                                    className="form-control custom-height-input"
                                                    onChange={(e) => this.props.setStreetNo(e.target.value)}
                                                    onKeyDown={this.blockInvalidChar}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group row">
                                              <div className="col-6">
                                                <select
                                                  name="place"
                                                  onChange={(e) =>
                                                    this.handleNewVariantInputChange(
                                                      e,
                                                      1
                                                    )
                                                  }
                                                  className="form-control"
                                                  required
                                                  data-msg="Please select address type"
                                                >
                                                  <option
                                                    key={"home" + 1}
                                                    value="Home"
                                                  >
                                                    Home
                                                  </option>
                                                  <option
                                                    key={"office" + 1}
                                                    value="Office"
                                                  >
                                                    Office
                                                  </option>
                                                  <option
                                                    key={"other" + 1}
                                                    value="Other"
                                                  >
                                                    {" "}
                                                    Other
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </>
                                          )}
                                        {/* ) */}
                                        {/* }) */}
                                        <div className="d-flex justify-content-center">
                                          {" "}
                                          {(sizejson.length > 0 ||
                                            addressesList.length > 0) &&
                                            this.state.addAddress && (
                                              <div className="row">
                                                <div className="col">
                                                  <button
                                                    disabled={this.isEmptyInputFields()}
                                                    className="btn btn-danger px-4"
                                                    type="button"
                                                    onClick={() =>
                                                      this.handleAddressSubmit(
                                                        "add"
                                                      )
                                                      }
                                                  >
                                                    {this.state.addAddress
                                                      ? "Add Address"
                                                      : "Update Address"}
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          {this.state.addAddress && (
                                            <div className="row">
                                              <div className="col">
                                                <button
                                                  disabled={
                                                    this.state
                                                      .isUpdateBtnDisabled
                                                  }
                                                  className="btn btn-danger px-4"
                                                  type="button"
                                                  onClick={() =>
                                                    this.onCancelClick("add")
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                          {" "}
                                          {(sizejson.length > 0 ||
                                            addressesList.length > 0) &&
                                            this.state.editAddress && (
                                              <div className="row">
                                                <div className="col">
                                                <button
                                                  disabled={this.isEmptyInputFields()}
                                                  className="btn btn-danger px-4"
                                                  type="button"
                                                  onClick={() =>
                                                    this.handleEditSubmit(
                                                      "edit"
                                                    )
                                                  }
                                                >
                                                  {this.state.editAddress
                                                    ? "Update"
                                                    : ""}
                                                </button>
                                              </div>
                                            </div>
                                            )}
                                          {this.state.editAddress && (
                                            <div className="row">
                                              <div className="col">
                                                <button
                                                  disabled={
                                                    this.state
                                                      .isUpdateBtnDisabled
                                                  }
                                                  className="btn btn-danger px-4"
                                                  type="button"
                                                  onClick={() =>
                                                    this.onCancelClick("edit")
                                                    }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className="">
                                          {/* <div className="form-group">
                                          
                                            <div className="col-sm-12">
                                              <select
                                                name="brand"
                                                className="form-control text-capitalize mt-2"
                                                required
                                                data-msg="Please select brand"
                                              >
                                                <option value="">Select Brand</option>
                                                {brands &&
                                                  brands.map(
                                                    (brand: any, index: any) =>
                                                      brand.is_active == 1 && (
                                                        <option key={index} {...(brand.brand_id == this.state.brand_id) && { selected: true }} value={JSON.stringify(brand)}>
                                                          {brand.brand_name}
                                                        </option>
                                                      )
                                                  )}
                                              </select>
                                            </div>
                                          </div> */}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </section>
                          </div>
                        </div>
                      )}
                  </div>
                  {/* ========== */}

                  <Modal
                    open={this.state.isOpen}
                    showCloseIcon={false}
                    onClose={this.onCloseModal}
                    center
                    styles={{
                      modal: {
                        width: "45vw",
                      },
                    }}
                    closeOnOverlayClick={false}
                  >
                    <div style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                      <i className="fa fa-info-circle modal-icon"></i>
                      <h2 className="text-center">
                        Welcome {localStorage.getItem("name")}
                      </h2>
                      <p className="text-center">Your Sales Target Are:</p>
                      <div className="row">
                        {/* Daily Target */}
                        <div className="col-lg-4">
                          <div className="d-block align-items-baseline badge badge-primary mb-3">
                            <div className="mt-1 mb-1 d-flex justify-content-around align-items-center flex-column agent-title">
                              <h5>Daily Target</h5>
                              {this.props.agentsTargetById ? (
                                <span>
                                  {priceunit}
                                  {(() => {
                                    const tempDailyTarget =
                                      this.props.agentsTargetById.temp_daily_target
                                        ? JSON.parse(this.props.agentsTargetById.temp_daily_target).daily_target
                                        : 0;
                                    const dailyTarget = this.props.agentsTargetById.daily_target || 0;
                                    const finalTarget =
                                      dailyTarget < tempDailyTarget
                                        ? Math.abs(tempDailyTarget)
                                        : tempDailyTarget;
                                    return Number.isInteger(finalTarget) ? finalTarget : finalTarget.toFixed(2);
                                  })()}
                                  ({Number.isInteger(this.props.agentsTargetById.daily_target)
                                    ? this.props.agentsTargetById.daily_target
                                    : this.props.agentsTargetById.daily_target?.toFixed(2) || 0})
                                </span>
                              ) : (
                                <span>0</span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Weekly Target */}
                        <div className="col-lg-4">
                          <div className="d-block align-items-baseline badge badge-primary mb-3">
                            <div className="mt-1 mb-1 d-flex justify-content-around align-items-center flex-column agent-title">
                              <h5>Weekly Target</h5>
                              {this.props.agentsTargetById ? (
                                <span>
                                  {priceunit}
                                  {(() => {
                                    const tempWeeklyTarget =
                                      this.props.agentsTargetById.temp_weekly_target
                                        ? JSON.parse(this.props.agentsTargetById.temp_weekly_target).weekly_target
                                        : 0;
                                    const weeklyTarget = this.props.agentsTargetById.weekly_target || 0;
                                    const finalTarget =
                                      weeklyTarget < tempWeeklyTarget
                                        ? Math.abs(tempWeeklyTarget)
                                        : tempWeeklyTarget;
                                    return Number.isInteger(finalTarget) ? finalTarget : finalTarget.toFixed(2);
                                  })()}
                                  ({Number.isInteger(this.props.agentsTargetById.weekly_target)
                                    ? this.props.agentsTargetById.weekly_target
                                    : this.props.agentsTargetById.weekly_target?.toFixed(2) || 0})
                                </span>
                              ) : (
                                <span>0</span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Monthly Target */}
                        <div className="col-lg-4">
                          <div className="d-block align-items-baseline badge badge-primary mb-3">
                            <div className="mt-1 mb-1 d-flex justify-content-around align-items-center flex-column agent-title">
                              <h5>Monthly Target</h5>
                              {this.props.agentsTargetById ? (
                                <span>
                                  {priceunit}
                                  {(() => {
                                    const tempMonthlyTarget =
                                      this.props.agentsTargetById.temp_monthly_target
                                        ? JSON.parse(this.props.agentsTargetById.temp_monthly_target).monthly_target
                                        : 0;
                                    const monthlyTarget = this.props.agentsTargetById.monthly_target || 0;
                                    const finalTarget =
                                      monthlyTarget < tempMonthlyTarget
                                        ? Math.abs(tempMonthlyTarget)
                                        : tempMonthlyTarget;
                                    return Number.isInteger(finalTarget) ? finalTarget : finalTarget.toFixed(2);
                                  })()}
                                  ({Number.isInteger(this.props.agentsTargetById.monthly_target)
                                    ? this.props.agentsTargetById.monthly_target
                                    : this.props.agentsTargetById.monthly_target?.toFixed(2) || 0})
                                </span>
                              ) : (
                                <span>0</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <button
                          onClick={() => {
                            this.onCloseModal();
                          }}
                          className="btn btn-success center"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          OKAY
                        </button>
                      </div>
                    </div>
                  </Modal>

                  {/* {this.state.showDetails && */}
                  <div className="col-lg-8 p-0">
                    <div className="card ph-card-customer">
                      <div className="card-body dom-table-card-box customer-detail-table">
                        {/* <button className="btn btn-danger px-4" style={{ position: "absolute", zIndex: 999, minHeight: "calc(2.25rem + 2px)" }} onClick={() => this.handleOrderRowCli1ck1(1)}>   {this.state.showDetails ? "Collapse" : "Expand"}</button> */}
                        <BootstrapTable
                          keyField="order_id"
                          data={orders}
                          columns={columns}
                          pagination={paginationFactory(paginationOptions)}
                          expandRow={this.expandRow}
                        />
                      </div>
                    </div>
                  </div>
                  {/* } */}
                </div>
              </div>
            </section>
            {/* {!_.isEmpty(storeData) &&
                            <div className="card-body">
                                <div className='container-fluid'>
                                    <p>Order Details:</p>
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order ID</label>
                                                <input type="email" disabled value={storeData.order_id} placeholder="Customer Name" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Total</label>
                                                <input type="email" disabled value={storeData.order_grossprice} placeholder="Phone Number" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Method</label>
                                                <input type="email" disabled value={storeData.delivery_status} placeholder="Email Address" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Status</label>
                                                <input type="email" disabled value={storeData.order_status_description} placeholder="Email Address" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col">
                                                <label className="form-control-label">Delivery Address</label>
                                                <input type="email" name="delivery_address" disabled value={storeData.delivery_address} placeholder="Customer Name" className="form-control" />
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        } */}
            {!_.isEmpty(customer) && (
              // <div className="card-body">
              //     <div className='container-fluid'>
              //         <p>Customer Details:</p>
              //         <form>
              //             <div className="form-group row">
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Name</label>
              //                     <input type="email" disabled value={customer.login_name} placeholder="Customer Name" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Phone</label>
              //                     <input type="email" disabled value={customer.phone_number} placeholder="Phone Number" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Email</label>
              //                     <input type="email" disabled value={customer.email_address} placeholder="Email Address" className="form-control" />
              //                 </div>
              //             </div>
              //             {customer.geo_address && customer.geo_address.full_address &&
              //                 <div className="form-group row">
              //                     <div className="col">
              //                         <label className="form-control-label">Delivery Address</label>
              //                         <input type="email" name="delivery_address" disabled value={customer.geo_address.full_address} onChange={this.props.handleAddressInput(customer.geo_address.full_address)} placeholder="Customer Name" className="form-control" />
              //                     </div>

              //                 </div>
              //             }
              //         </form>
              //     </div>
              // </div>
              <div className="container-fluid"></div>
            )}
            {/* <div className="container-fluid">
              <div className="d-flex ml-4">
                <div className="form-group">
                  <Link to="/menu">
                    <button className="btn btn-danger px-4">Start Order</button>
                  </Link>
                </div>
              </div>
            </div> */}
            {this.state.newUser === true && (
              <div>
                <section className="forms pt-3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    First name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="firstname"
                                    type="text"
                                    name="firstname"
                                    required
                                    placeholder="Please Enter First Name"
                                    data-msg="Please enter First Name"
                                    className="form-control"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="lastname"
                                    type="text"
                                    name="lastname"
                                    required
                                    data-msg="Please enter Last Name"
                                    className="form-control"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Email
                                  </label>
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    required
                                    placeholder="Please Enter Email"
                                    data-msg="Please enter Email"
                                    className="form-control"
                                    onChange={this.props.validateEmail}
                                  />
                                  {isValidEmail == false && email !== "" && (
                                    <small className="form-text text-danger">
                                      email is not correct
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Phone <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="phone"
                                    type="tel"
                                    maxLength={13}
                                    name="newUserphone"
                                    defaultValue={this.state.newUserphone}
                                    required
                                    placeholder="Please Enter Phone"
                                    data-msg="Please enter Phone"
                                    className="form-control"
                                    onChange={this.validatePhone}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label className="form-control-label label-margin">
                                    Flat / House / Office Number
                                  </label>
                                  <input
                                    id="houseNO"
                                    type="text"
                                    name="houseNo"
                                    required
                                    data-msg="Please enter House Number"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.props.setHouseNo(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group d-flex justify-content-end mt-4">
                              <button
                                className="btn btn-danger"
                                disabled={!this.isCustomerReady()}
                                onClick={this.handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            <section className="tables pt-2">
              {/* <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={orders}
                          search={true}
                          pagination={orders.length > 10 && true}
                          options={options}
                          hover
                        >
                          <TableHeaderColumn
                            dataField="order_id"
                            csvHeader="Order Id"
                            dataSort={true}
                            isKey
                          >
                            Order Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="brand"
                            csvHeader="Brand"
                            dataFormat={brandFormatter}
                            dataSort={true}
                            formatExtraData={this.props}
                          >
                            Brand
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="store_name"
                            csvHeader="Store"
                            columnTitle
                          >
                            Store
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_status"
                            csvHeader="Order Type"
                            columnTitle
                          >
                            Order Type
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_channel"
                            csvHeader="Order Channel"
                            columnTitle
                          >
                            Order Channel
                          </TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField='action' width='150' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField='action' width='180' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn> */}
              {/* <TableHeaderColumn
                            dataField="login_name"
                            csvHeader="Customer Name"
                            columnTitle
                          >
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="phone_number"
                            csvHeader="Phone"
                            columnTitle
                          >
                            Phone
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_address"
                            csvHeader="Address"
                            width="150"
                            columnTitle
                          >
                            Address
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_grossprice"
                            dataFormat={priceFormatter}
                            csvHeader="Price"
                          >
                            Order Price
                          </TableHeaderColumn> */}
              {/* <TableHeaderColumn width='100' columnTitle>Delivery Zone</TableHeaderColumn> */}
              {/* <TableHeaderColumn
                            dataField="payment_method"
                            csvHeader="Payment Method"
                            columnTitle
                          >
                            Payment Method
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_status_description"
                            csvHeader="Order Status"
                            columnTitle
                          >
                            Order Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="date_created"
                            csvHeader="Order Recieved"
                            dataFormat={dateFormatter}
                            columnTitle
                          >
                            Order Recieved
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="action"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Repeat Order
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="action"
                            width="70"
                            dataFormat={complaintFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
            </section>
            <InvoiceComp data={this.props} />
            <ComplaintComp data={this.props} />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
    <div>
      <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.customer.ordersList,
    orderdata: state.customer.orderDetail,
    cart: state.cart.cartData,
    customer: state.customer.customerData,
    taxdata: state.cart.taxData,
    stores: state.customer.storesList,
    firstname: state.customer.firstname,
    lastname: state.customer.lastname,
    email: state.customer.email,
    phone: state.customer.phone,
    delivery_address: state.menu.delivery_address,
    isValidEmail: state.customer.isValidEmail,
    time: state.customer.clockCount,
    houseNo: state.menu.houseNo,
    Block: state.menu.Block,
    Area: state.menu.Area,
    City: state.menu.City,
    storeData: state.customer.storeData,
    callduration: state.customer.clockCount,
    psiList: state.customer.psiList,
    addressData: state.customer.addressData,
    reasonsList: state.customerComplaint.complaintReasonList,
    cities_portal: state.trade_zones_areas.cities,
    tradeZones_portal: state.trade_zones_areas.tradeZones,
    tradeAreas_portal: state.trade_zones_areas.tradeAreas,
    cityName: state.trade_zones_areas.cityName,
    tradeZoneName: state.trade_zones_areas.tradeZoneName,
    tradeAreaName: state.trade_zones_areas.tradeAreaName,
    is_saved_address_selected: state.customer.is_saved_address_selected,
    agentsTargetById: state.agent.agentsTargetById,
    // brands: state.brand.brands,
    // brand: state.brand.selected_brand,
    selectedAddress: state.menu.selectedAddress,
    customerPhoneData: state.customer.customerPhoneData,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    streetName: state.menu.streetName,
    streetNo: state.menu.streetNo,
    Additional_address_info: state.menu.Additional_address_info,
    selectedRow: state.order.selectedRow,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    orderDetails: function (orderId: any) {
      dispatch(orderDetail(orderId));
    },
    customerDetail: (data: any) => {
      dispatch(customerDetail(data));
    },
    BlockCustomer: (data: any) => {
      dispatch(BlockCustomer(data))
    },
    handleActivation: () => {
      dispatch(ActivateAccount())
    },
    handleCustomerInput: (event: any) => {
      dispatch(handleCustomerInput(event));
    },
    addCustomer: (data: any) => {
      dispatch(addCustomer(data));
    },
    editAddress: (id: any, data: any) => {
      dispatch(editAddress(id, data));
    },
    validateEmail: (event: any) => {
      dispatch(validateEmail(event));
    },
    handleAddressInput: (address: any) => {
      dispatch(handleAddressInput(address));
    },
    setStreetName: function (address: String) {
      dispatch(handleStreetName(address))
    },
    setStreetNo: function (address: String) {
      dispatch(handleStreetNo(address))
    },
    storesList: function () {
      dispatch(getStores());
    },
    getTaxValue: (state_id: any) => {
      dispatch(getTaxValue(state_id));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    StartClockTimer: function (time: any) {
      dispatch(StartClockTimer(time));
    },
    handleMenuInput: (event: any) => {
      dispatch(handleMenuInput(event));
    },
    submitComplaint: function (data: any, callStatsObj: any) {
      dispatch(submitComplaint(data, callStatsObj));
    },
    trackOrder: function (data: any) {
      dispatch(trackOrder(data));
    },
    stopTimer: function () {
      dispatch(stopTimer());
    },
    stopCount: function () {
      dispatch(stopCount());
    },
    // getAgentTrgetByAgentId: function () {
    //   dispatch(getAgentTrgetByAgentId());
    // },
    addAddress: (data: any) => {
      dispatch(addAddressUser(data));
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
    complaintReasonsList: function () {
      dispatch(complaintReasonsList());
    },
    getCities: function () {
      dispatch(getCitiesWeb());
    },
    getTradeZones: function (id: any) {
      dispatch(getTradeZonesWeb(id))
    },
    getTradeAreas: function (id: any) {
      dispatch(getTradeAreas(id))
    },
    setTradeZoneName: function (name: String, value: any) {
      dispatch(setTradeZoneName(name, value))
    },
    setTradeAreaName: function (name: String, value: any) {
      dispatch(setTradeAreaName(name, value))
    },
    setCityName: function (name: String) {
      dispatch(setCityName(name))
    },
    setHouseNo: function (address: String) {
      dispatch(handleHouseNo(address))
    },
    isSavedAddressSelected: (value: any, address: any) => {
      dispatch(isSavedAddressSelected(value, address))
    },
    // GenesysCustomerWidget: (value: any) => {
    //   dispatch(GenesysCustomerWidget(value))
    // },
    // brandsList: () => {
    //   dispatch(brandsList())
    // },
    // saveBrand: (brand_id: any) => {
    //   dispatch(saveBrand(brand_id))
    // },
    saveSelectedAddress: (address: any) => {
      dispatch(saveSelectedAddress(address))
    },
    // reOrdersFunction: (order_id: any, mode: any) => {
    //   dispatch(getLastOrderForReOrder(order_id, mode))
    // },
    findLocalStoreWithoutMap: function (store: any) {
      dispatch(findLocalStoreWithoutMap(store))
    },
    showorderItemss: function (id: any, orders: any) {
      dispatch(showorderItems(id, orders))
    },
    receiveCustomerData: function (data: any) {
      // console.log("data-reached-here", data);
      dispatch(receiveCustomerData(data));
    },
    saveCallrecord: function (data: any) {
      dispatch(saveCallrecord(data))
    },
    updateSelectedRow: (row: any) => {
      dispatch(updateSelectedRow(row))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
