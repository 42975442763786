import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { logoutUser, countryList } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { EditAgentProps, EditAgentState } from '../../interfaces/agent';
import { editAgent, getAgent } from '../../redux/actions/agentAction';

class EditAgent extends Component<EditAgentProps, EditAgentState> {
    constructor(props: any) {
        super(props);
        this.state = { 
            firstname: "",
            lastname: "",
            email: "",
            city: "",
            phone: "",
            cnic: "",
            country: "",
            address: "",
            isValidEmail: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getAgent(id);
        this.props.countryList();
        //this.props.storesList();
        document.title = "DDS | Driver"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            firstname: nextProps.agentData.first_name,
            lastname: nextProps.agentData.last_name,
            email: nextProps.agentData.email_address,
            city: nextProps.agentData.city,
            phone: nextProps.agentData.phone_number,
            country: nextProps.agentData.country_id,
            address: nextProps.agentData.address,
            })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isUserReady = () => {
        let { firstname, country, address, isValidEmail } = this.state;
            return (firstname !== "" && country !== "" && address !== "" && isValidEmail === true);
    }
    validateEmail(event: { target: { name: any; value: any; }; }) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(event.target.value) === false) {
            this.setState({ email: event.target.value, isValidEmail: false })
        } else {
            this.setState({ email: event.target.value, isValidEmail: true });
        }
    };
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { firstname, lastname, email, cnic, phone, city, country, address } = this.state;
        let id = this.props.match.params.id;
        let data = {
            first_name: firstname,  
            last_name: lastname,
            user_name: lastname === '' ? firstname : firstname + " " + lastname,
            email_address: email,
            city: city,
            phone_number: phone, 
            country_id: country,
            address: address,
        }
        this.props.editAgent(id, data)
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, agentData, countries, stores } = this.props;
        let { isValidEmail, email } = this.state;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        console.log("Agent Data: ", this.props.agentData);
        return (
            <div className="page">
                 <CheckChanges path="/edit-agent" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Agent Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/agents" className="text-primary">Agents</Link></li>
                                <li className="breadcrumb-item active">Edit Agent</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <form method="post" className="form-validate">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label ">First Name <span className="text-danger">*</span></label>
                                                                <input id="firstname" type="text" name="firstname" defaultValue={this.state.firstname} required data-msg="Please enter first name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Last Name</label>
                                                                <input id="lastname" type="text" name="lastname" defaultValue={this.state.lastname} required data-msg="Please enter last name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Email <span className="text-danger">*</span></label>
                                                                <input id="email" type="email" name="email" defaultValue={this.state.email} required data-msg="Please enter email" className="input-material" onChange={this.handleInputChange} />
                                                                {(isValidEmail == false && email !== "") &&
                                                                    <small className="form-text text-danger">email is not correct</small>
                                                                }
                                                            </div>                                                        
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Phone</label>
                                                                <input id="phone" type="text" name="phone" defaultValue={this.state.phone} required data-msg="Please enter city" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">City</label>
                                                                <input id="city" type="text" name="city" defaultValue={this.state.city} required data-msg="Please enter city" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                    <option disabled>Select Country</option>
                                                                    {countries &&
                                                                        countries.map((country: any, index: any) => (
                                                                            <option key={index} value={country.country_id} {...agentData.country_id == country.country_id && { selected: true }}>{country.country_name}</option>
                                                                        ))
                                                                    } </select>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                       
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Address <span className="text-danger">*</span></label>
                                                                <input id="address" type="text" name="address" value={this.state.address} required data-msg="Please enter address" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group float-right">
                                                        <button onClick={this.handleSubmit} disabled={!this.isUserReady()} className="btn btn-danger">Update User</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        agentData: state.agent.agentData,
        // message: state.setting.message,
        // isUpdated: state.setting.isUpdated,
        //roles: state.setting.roles,
        countries: state.user.countries,
        //stores: state.order.data
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editAgent: function (id: any, data: any) {
            dispatch(editAgent(id, data));
        },
        getAgent: function (id: number) {
            dispatch(getAgent(id));
        },
        countryList: function () {
            dispatch(countryList())
        },
        // storesList: function () {
        //     dispatch(storesList())
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAgent);