import {
  CUSTOMER_DETAIL,
  CUSTOMER_ORDERS_LIST,
  SHOW_TIMER,
  HANDLE_INPUT,
  ORDER_DATA,
  ORDER_DETAIL,
  SELECT_STORE,
  STORES_LIST,
  TIMER_START,
  VALIDATE_EMAIL,
  ALL_STORES_LIST,
  ORDER,
  PAYMENT,
  ADDRESS_LIST,
  RECEIVE_CUSTOMER_DATA,
  SELECTED_FROM_SAVED_ADDRESS,
  SELECTED_ADDRESS,
  RESET_CUSTOMER_FORM_DATA,
  RESET_CUSTOMER_ADDRESS,
} from "../actions/customerType";
const initialState: any = {
  customerData: {},
  firstname: "",
  lastname: "",
  email: "",
  customerPhoneData: null,
  phone: "",
  isValidEmail: "",
  ordersList: [] || "",
  storesList: [] || "",
  allStoresList: [] || "",
  addressData: [] || '',
  storeData: {},
  orderDetail: {},
  is_saved_address_selected: false,
  selectedStore: {},
  deliveryfee: 0,
  agentStats: {},
  clockCount: 0,
  isActive: false,
  showCount: false,
  order_id: "",
  successFlag: false,
};

const customerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HANDLE_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case RESET_CUSTOMER_FORM_DATA:
      return {
        ...state,
        firstname: "",
        lastname: "",
        email: ""
      };
    case RESET_CUSTOMER_ADDRESS:
      return {
        ...state,
        addressData: []
      }
    case VALIDATE_EMAIL:
      return {
        ...state,
        email: action.email,
        isValidEmail: action.isValidEmail,
      };
    case CUSTOMER_DETAIL:
      return {
        ...state,
        customerData: action.payload,
      };
    case CUSTOMER_ORDERS_LIST:
      return {
        ...state,
        ordersList: action.payload,
      };
    case ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case STORES_LIST:
      return {
        ...state,
        storesList: action.payload,
      };
    case ALL_STORES_LIST:
      return {
        ...state,
        allStoresList: action.payload,
      };
    case SELECT_STORE:
      return {
        ...state,
        selectedStore: action.selectedStore,
        deliveryfee: action.deliveryfee,
      };
    case SHOW_TIMER:
      return {
        ...state,
        showCount: action.showCount,
      };
    case TIMER_START:
      return {
        ...state,
        isActive: action.isActive,
        clockCount: action.CounterIncrement,
      };
    case ORDER_DATA:
      return {
        ...state,
        storeData: action.storeData,
      };
    case ORDER:
      return {
        ...state,
        order_id: action.order_id,
      };
    case PAYMENT:
      return {
        ...state,
        paymentSuccess: action.PaymentSuccess,
        successFlag: action.successFlag,
      };
    case RECEIVE_CUSTOMER_DATA:
      return {
        ...state,
        customerPhoneData: action.payload,
      };
    case SELECTED_FROM_SAVED_ADDRESS:
      return {
        ...state,
        is_saved_address_selected: action.payload,
      };
    case SELECTED_ADDRESS:
      return {
        ...state,
        savedAddress: action.payload,
      };
      case ADDRESS_LIST:
        return {
          ...state,
          addressData: action.payload,
        };
    default:
      return state;
  }
};
export default customerReducer;
