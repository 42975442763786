import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from '../components/footer/login'
import { ForgotPassState, ForgotPassProps } from '../interfaces/user'
import { connect } from 'react-redux'
import { forgotPassword } from '../redux'
import "./login.css"

class ForgotPass extends Component<ForgotPassProps, ForgotPassState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            email: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "DDS | ForgotPassword"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { email } = this.state;
        this.props.forgotPassword(email);
    }
    render() {
        let msg;
        let messcolor;
        const { isReset, message } = this.props;
        if (isReset) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page login-page">
                <div className="form-wrapper">
                    <div className="form-card">
                        <div className="p-5">
                            <div className='mb-3'>
                                <div className="form-card-header">
                                    <img className='login-form-logo' src={'/assets/img/domLogo.png'} alt="Dominos" />
                                    <h1 className='form-header-text'>Domino's Delivery System</h1>
                                </div>
                            </div>
                            <form className="form-validate">
                                <div className="form-group">
                                    <label className="form-control-label">Email</label>
                                    <input id="login-username" type="text" name="email" required data-msg="Please enter your email" className="input-material" onChange={this.handleInputChange} />
                                </div>
                                <button id="reset" onClick={this.handleSubmit} className="btn btn-danger">Reset Password</button>
                            </form>

                             <div className='mt-3'>
                                <Link to="/" className="text-danger">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        message: state.user.message,
        isReset: state.user.isReset
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        forgotPassword: function (email: any) {
            dispatch(forgotPassword(email));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);